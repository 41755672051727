import { handleHostToAppPerformanceMetrics as e } from "./handlers.js";
class a {
  static storeCallbackInformation(e, t) {
    a.callbackInformation.set(e, t);
  }
  static clearMessages() {
    a.callbackInformation.clear();
  }
  static deleteMessageInformation(e) {
    a.callbackInformation.delete(e);
  }
  static handleOneWayPerformanceMetrics(a, t, n) {
    const o = a.monotonicTimestamp;
    o && n ? e({
      actionName: a.func,
      messageDelay: n - o,
      requestStartedAt: o
    }) : t("Unable to send performance metrics for event %s", a.func);
  }
  static handlePerformanceMetrics(t, n, o, s) {
    const c = a.callbackInformation.get(t);
    c && n.monotonicTimestamp && s ? (e({
      actionName: c.name,
      messageDelay: s - n.monotonicTimestamp,
      requestStartedAt: c.calledAt
    }), a.deleteMessageInformation(t)) : o("Unable to send performance metrics for callback %s with arguments %o", t.toString(), n.args);
  }
}
a.callbackInformation = new Map();
export { a as default };