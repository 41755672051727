"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KeywordRecognizerService = void 0;
var ContextItem_1 = require("../../Core/Nlp/ContextItem");
var Keyword_1 = require("./Keyword");
var KeywordRecognzierIntepretation_1 = require("./KeywordRecognzierIntepretation");
var KeywordRecognizerService = /** @class */function () {
  function KeywordRecognizerService(keywords, defaultFallbackIntent) {
    this.keywords = keywords;
    this.defaultFallbackIntent = defaultFallbackIntent;
  }
  KeywordRecognizerService.loadKeywords = function (dialogConfig) {
    return dialogConfig.cards.filter(function (card) {
      var _a, _b;
      return ((_a = card.understanding) === null || _a === void 0 ? void 0 : _a.keywords) !== undefined || ((_b = card.understanding) === null || _b === void 0 ? void 0 : _b.context) !== undefined;
    }).map(function (card) {
      var keywordValues;
      if (card.understanding.keywords !== undefined) {
        // TODO use keywords? instead
        keywordValues = card.understanding.keywords.map(function (keyword) {
          // TODO Use named groups
          // TODO Extract this regex and name it proper
          var match = keyword.match(/\/(.+)\/([gimy]{0,4})/);
          if (match !== null) {
            return new RegExp(match[1], match[2]);
          }
          return keyword;
        });
      }
      return new Keyword_1.Keyword(keywordValues, card.understanding.intent, card.understanding.context === undefined ? undefined : card.understanding.context.map(function (context) {
        return new ContextItem_1.ContextItem(context);
      }), card.understanding.intentGroup);
    });
  };
  KeywordRecognizerService.prototype.interpret = function (text, language, nlpSessionId, contexts) {
    var _this = this;
    if (contexts === void 0) {
      contexts = [];
    }
    var contextKeywords = this.filterKeywordsByContext(contexts);
    var matchingKeywords = contextKeywords.filter(function (keyword) {
      return _this.matchKeyword(keyword, text);
    });
    var bestMatchingKeyword = KeywordRecognizerService.decideBestMatchingKeyword(matchingKeywords);
    var interpretation;
    if (bestMatchingKeyword === undefined) {
      interpretation = new KeywordRecognzierIntepretation_1.KeywordRecognzierIntepretation(text, this.defaultFallbackIntent, true, language, undefined);
    } else {
      interpretation = new KeywordRecognzierIntepretation_1.KeywordRecognzierIntepretation(text, bestMatchingKeyword.intentName, false, language, undefined, bestMatchingKeyword.intentGroup);
    }
    return Promise.resolve(interpretation);
  };
  KeywordRecognizerService.prototype.matchKeyword = function (keyword, text) {
    if (keyword.values === undefined || keyword.values.length === 0) {
      return true;
    }
    return keyword.values.some(function (value) {
      if (!KeywordRecognizerService.isRegex(value)) {
        value = new RegExp("^".concat(KeywordRecognizerService.escapeRegex(value), "$"), 'i');
      }
      return text.match(value) !== null;
    });
  };
  KeywordRecognizerService.prototype.filterKeywordsByContext = function (contextList) {
    // TODO separate options
    // All the required contexts have to be in the input context list / if we don't have required one is ok too
    return this.keywords.filter(function (keyword) {
      return keyword.requiredContext.filter(function (requiredContext) {
        return contextList.findIndex(function (context) {
          return requiredContext.name.toLowerCase() === context.name.toLowerCase();
        }) === -1;
      }).length === 0;
    });
  };
  KeywordRecognizerService.decideBestMatchingKeyword = function (matchingKeywords) {
    if (matchingKeywords.length > 1) {
      var bestMatchingKeywords = matchingKeywords.filter(function (keyword) {
        return keyword.values !== undefined && keyword.values.length > 0;
      });
      if (bestMatchingKeywords.length > 1) {
        throw new Error('More than one Keyword with the same keywords list and context');
      }
      if (bestMatchingKeywords.length === 0) {
        throw new Error('More than one Keyword with the same context and no keywords list');
      }
      return bestMatchingKeywords[0];
    }
    if (matchingKeywords.length === 1) {
      return matchingKeywords[0];
    }
    return undefined;
  };
  KeywordRecognizerService.isRegex = function (value) {
    return value instanceof RegExp;
  };
  KeywordRecognizerService.escapeRegex = function (value) {
    return value.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  };
  return KeywordRecognizerService;
}();
exports.KeywordRecognizerService = KeywordRecognizerService;
