"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContextItem = void 0;
var ContextItem = /** @class */function () {
  function ContextItem(name, lifespan) {
    if (lifespan === void 0) {
      lifespan = 1;
    }
    this.name = name;
    this.lifespan = lifespan;
  }
  return ContextItem;
}();
exports.ContextItem = ContextItem;
