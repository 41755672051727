import { urlOpenHelper as t, urlSubmitHelper as o, dialogTelemetryVersionNumber as r } from "../../../internal/dialogHelpers.js";
import { ensureInitialized as i } from "../../../internal/internalAPIs.js";
import { getApiVersionTag as n } from "../../../internal/telemetry.js";
import { DialogDimension as l } from "../../constants.js";
import { runtime as e } from "../../runtime.js";
import * as m from "./bot.js";
export { m as bot };
import * as s from "./parentCommunication.js";
export { s as parentCommunication };
function a(o, i, l) {
  t(n(r, "dialog.url.open"), o, i, l);
}
function p(t, i) {
  o(n(r, "dialog.url.submit"), t, i);
}
function u() {
  return i(e) && void 0 !== (e.supports.dialog && e.supports.dialog.url);
}
function f(t) {
  return {
    url: t.url,
    height: t.size ? t.size.height : l.Small,
    width: t.size ? t.size.width : l.Small,
    title: t.title,
    fallbackUrl: t.fallbackUrl
  };
}
function c(t) {
  const o = f(t);
  return o.completionBotId = t.completionBotId, o;
}
export { c as getDialogInfoFromBotUrlDialogInfo, f as getDialogInfoFromUrlDialogInfo, u as isSupported, a as open, p as submit };