import {Component, OnInit} from '@angular/core';
import {ImmediatelyRenderedMessageComponent} from 'botato-angular-lib';
import {HelpResponseItemData} from './HelpResponseItemData';
import {ResponseItemDataTextService} from '../../response-item-data-text.service';

@Component({
    selector: 'mm-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
})
export class HelpComponent extends ImmediatelyRenderedMessageComponent implements OnInit {

    public static TYPE = 'Help';
    public responseItemData: HelpResponseItemData;
    public textMessage: string[];
    public isDeactivated: boolean;

    constructor(private responseItemDataTextService: ResponseItemDataTextService) {
        super();
    }

    public ngOnInit(): void {

        this.responseItemData = this.message.data.responseItemData as HelpResponseItemData;
        this.textMessage = this.responseItemData.text.message.split(' | | ').map(message =>
            this.responseItemDataTextService.replaceText(message));
    }

}
