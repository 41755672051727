"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AdapterConfiguration = void 0;
var AdapterConfiguration = /** @class */function () {
  function AdapterConfiguration(typingTimeoutCalculator) {
    this.typingTimeoutCalculator = typingTimeoutCalculator;
  }
  AdapterConfiguration.NEW_LINE_SEPARATOR = ' | ';
  AdapterConfiguration.LINK_REGEX = /\[(.*?)\]\((.*?)\)/g;
  AdapterConfiguration.BOLD_REGEX = /\*{2}(.*?)\*{2}/g;
  return AdapterConfiguration;
}();
exports.AdapterConfiguration = AdapterConfiguration;
