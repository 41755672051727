import { updateResizeHelper as r, dialogTelemetryVersionNumber as t } from "../../internal/dialogHelpers.js";
import { ensureInitialized as o } from "../../internal/internalAPIs.js";
import { getApiVersionTag as e } from "../../internal/telemetry.js";
import { runtime as i } from "../runtime.js";
function n(o) {
  r(e(t, "dialog.update.resize"), o);
}
function p() {
  return !(!o(i) || !i.supports.dialog) && !!i.supports.dialog.update;
}
export { p as isSupported, n as resize };