import {Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {BotatoChatManagerService, BotatoWidgetManagerService} from 'botato-angular-lib';
import {ChatStartService} from './chat-start.service';
import {Subscription} from 'rxjs';

import {LIB_AUTH_SERVICE_TOKEN, LibAuthService} from './libAuthService';

@Component({
    selector: 'mm-chatbot',
    templateUrl: './chatbot.component.html',
    styleUrls: ['./chatbot.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChatbotComponent implements OnInit, OnDestroy {
    @Input()
    public botId: string;

    private tokenSubscription: Subscription;

    constructor(private chatManager: BotatoChatManagerService,
                private widgetManager: BotatoWidgetManagerService,
                private chatStartService: ChatStartService,
                @Inject(LIB_AUTH_SERVICE_TOKEN) private authService: LibAuthService) {
    }

    public ngOnInit(): void {
        this.tokenSubscription = this.authService.authentication$
            .subscribe(() => {
                    if (!this.chatStartService.isWaitingForNotification) {
                        this.startChat();
                    }
                }
            );

        this.widgetManager.chatWindowState$.subscribe(newState => {
            if (newState === 'open') {
                this.chatManager.scrollToBottom(false);
            }
        });
    }

    private startChat(): void {
        if (!this.chatStartService.getOpenState()) {
            this.chatStartService.setOpenState(true);
            if (!this.chatManager.isAlreadyConnectedOnOtherClient()) {
                this.chatManager.start();
            }
        }
    }

    public ngOnDestroy(): void {
        if (this.tokenSubscription !== undefined) {
            this.tokenSubscription.unsubscribe();
        }
    }
}
