import {Component, OnDestroy, OnInit} from '@angular/core';
import {QuizResponseItemData} from '../../type/QuizResponseItemData';
import {Subscription} from 'rxjs';
import {BotatoChatManagerService, DelayedRenderedMessageComponent} from 'botato-angular-lib';
import {DeactivateMessageService} from '../../../deactivate-message.service';
import {ReflectionFreeTextQuiz} from './ReflectionFreeTextQuiz';
import {ReflectionFreeTextQuizResponseItemDataText} from './ReflectionFreeTextQuizResponseItemDataText';
import {EnvironmentService} from '../../../../../services/EnvironmentService';

@Component({
    selector: 'mm-reflection-free-text-quiz',
    templateUrl: './reflection-free-text-quiz.component.html'
})
export class ReflectionFreeTextQuizComponent extends DelayedRenderedMessageComponent implements OnInit, OnDestroy {

    public static TYPE = 'ReflectionFreeText';
    public responseItemData: QuizResponseItemData<ReflectionFreeTextQuiz, ReflectionFreeTextQuizResponseItemDataText, unknown>;
    public isDeactivated: boolean;
    private subscription: Subscription;

    constructor(private deactivateMessageService: DeactivateMessageService,
                private environmentService: EnvironmentService,
                private botatoChatManagerService: BotatoChatManagerService) {
        super();
    }

    public ngOnInit(): void {
        this.subscription = this.deactivateMessageService.deactivateOldCustomMessage(this.message.data);

        this.isDeactivated = this.message.data.deactivated !== undefined ? this.message.data.deactivated : false;

        this.responseItemData = this.message.data.responseItemData;
    }

    public skipQuestion(): void {

        this.message.data.deactivated = true;
        this.botatoChatManagerService.sendUserMessage(this.responseItemData.text.skipButtonText, 'quickreply');
    }

    public createAdaptiveCardBackendImage(): string {
        return `${this.environmentService.environment.manageUrl}/assets/staticImages/reflectionBackgroundImage1.png`;
    }

    public ngOnDestroy(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }

    public mediaLoaded(): void {
        this.componentRenderingCompleted();
    }

    public override onAnyMessageSent(): void {
        if (!this.isDeactivated) {

            this.message.data.deactivated = true;
        }
    }
}
