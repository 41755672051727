"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Timeout = void 0;
var Timeout = /** @class */function () {
  function Timeout() {}
  Timeout.wait = function (timeout) {
    return new Promise(function (resolve) {
      setTimeout(function () {
        resolve(undefined);
      }, timeout);
    });
  };
  return Timeout;
}();
exports.Timeout = Timeout;
