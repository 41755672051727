"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WebSocketSpecificData = void 0;
var WebSocketSpecificData = /** @class */function () {
  function WebSocketSpecificData(botResultOptions, customBotData) {
    this.botResultOptions = botResultOptions;
    this.customBotData = customBotData;
  }
  return WebSocketSpecificData;
}();
exports.WebSocketSpecificData = WebSocketSpecificData;
