import { botUrlOpenHelper as r, dialogTelemetryVersionNumber as o } from "../../../internal/dialogHelpers.js";
import { ensureInitialized as t } from "../../../internal/internalAPIs.js";
import { getApiVersionTag as i } from "../../../internal/telemetry.js";
import { runtime as n } from "../../runtime.js";
function l(t, n, l) {
  r(i(o, "dialog.url.bot.open"), t, n, l);
}
function e() {
  return t(n) && void 0 !== (n.supports.dialog && n.supports.dialog.url && n.supports.dialog.url.bot);
}
export { e as isSupported, l as open };