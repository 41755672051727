import { __rest as o } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { errorRuntimeNotInitialized as e, errorRuntimeNotSupported as s } from "../internal/constants.js";
import { GlobalVars as i } from "../internal/globalVars.js";
import { getLogger as t } from "../internal/telemetry.js";
import { compareSDKVersions as n, deepFreeze as r } from "../internal/utils.js";
import { HostClientType as a, teamsMinAdaptiveCardVersion as p } from "./constants.js";
const l = t("runtime"),
  d = 4;
function u(o) {
  return o.apiVersion === d;
}
function c(o) {
  if (u(o)) return !0;
  throw -1 === o.apiVersion ? new Error(e) : new Error(s);
}
let g = {
  apiVersion: -1,
  supports: {}
};
const m = {
    apiVersion: 4,
    isNAAChannelRecommended: !1,
    hostVersionsInfo: p,
    isLegacyTeams: !0,
    supports: {
      appInstallDialog: {},
      appEntity: {},
      call: {},
      chat: {},
      conversations: {},
      dialog: {
        card: {
          bot: {}
        },
        url: {
          bot: {},
          parentCommunication: {}
        },
        update: {}
      },
      interactive: {},
      logs: {},
      meetingRoom: {},
      menus: {},
      monetization: {},
      notifications: {},
      pages: {
        config: {},
        backStack: {},
        fullTrust: {}
      },
      remoteCamera: {},
      teams: {
        fullTrust: {}
      },
      teamsCore: {},
      video: {
        sharedFrame: {}
      }
    }
  },
  b = [a.desktop, a.web, a.rigel, a.surfaceHub, a.teamsRoomsWindows, a.teamsRoomsAndroid, a.teamsPhones, a.teamsDisplays],
  y = [a.android, a.ios, a.ipados],
  f = [...b, ...y];
function h(o) {
  let e = o;
  if (e.apiVersion < d && v.forEach(o => {
    e.apiVersion === o.versionToUpgradeFrom && (e = o.upgradeToNextVersion(e));
  }), u(e)) return e;
  throw new Error("Received a runtime that could not be upgraded to the latest version");
}
const v = [{
    versionToUpgradeFrom: 1,
    upgradeToNextVersion: o => {
      var e;
      return {
        apiVersion: 2,
        hostVersionsInfo: void 0,
        isLegacyTeams: o.isLegacyTeams,
        supports: Object.assign(Object.assign({}, o.supports), {
          dialog: o.supports.dialog ? {
            card: void 0,
            url: o.supports.dialog,
            update: null === (e = o.supports.dialog) || void 0 === e ? void 0 : e.update
          } : void 0
        })
      };
    }
  }, {
    versionToUpgradeFrom: 2,
    upgradeToNextVersion: e => {
      const s = e.supports,
        i = o(s, ["appNotification"]);
      return Object.assign(Object.assign({}, e), {
        apiVersion: 3,
        supports: i
      });
    }
  }, {
    versionToUpgradeFrom: 3,
    upgradeToNextVersion: o => {
      var e, s, i, t, n;
      return {
        apiVersion: 4,
        hostVersionsInfo: o.hostVersionsInfo,
        isNAAChannelRecommended: o.isNAAChannelRecommended,
        isLegacyTeams: o.isLegacyTeams,
        supports: Object.assign(Object.assign({}, o.supports), {
          dialog: o.supports.dialog ? {
            card: null === (e = o.supports.dialog) || void 0 === e ? void 0 : e.card,
            url: {
              bot: null === (i = null === (s = o.supports.dialog) || void 0 === s ? void 0 : s.url) || void 0 === i ? void 0 : i.bot,
              parentCommunication: (null === (t = o.supports.dialog) || void 0 === t ? void 0 : t.url) ? {} : void 0
            },
            update: null === (n = o.supports.dialog) || void 0 === n ? void 0 : n.update
          } : void 0
        })
      };
    }
  }],
  T = {
    "1.0.0": [{
      capability: {
        pages: {
          appButton: {},
          tabs: {}
        },
        stageView: {}
      },
      hostClientTypes: b
    }],
    "1.9.0": [{
      capability: {
        location: {}
      },
      hostClientTypes: f
    }],
    "2.0.0": [{
      capability: {
        people: {}
      },
      hostClientTypes: f
    }, {
      capability: {
        sharing: {}
      },
      hostClientTypes: [a.desktop, a.web]
    }],
    "2.0.1": [{
      capability: {
        teams: {
          fullTrust: {
            joinedTeams: {}
          }
        }
      },
      hostClientTypes: [a.android, a.desktop, a.ios, a.teamsRoomsAndroid, a.teamsPhones, a.teamsDisplays, a.web]
    }, {
      capability: {
        webStorage: {}
      },
      hostClientTypes: [a.desktop]
    }],
    "2.0.5": [{
      capability: {
        webStorage: {}
      },
      hostClientTypes: [a.android, a.ios]
    }],
    "2.0.8": [{
      capability: {
        sharing: {}
      },
      hostClientTypes: [a.android, a.ios]
    }]
  },
  V = l.extend("generateBackCompatRuntimeConfig");
function C(o, e) {
  const s = Object.assign({}, o);
  for (const i in e) Object.prototype.hasOwnProperty.call(e, i) && ("object" != typeof e[i] || Array.isArray(e[i]) ? i in o || (s[i] = e[i]) : s[i] = C(o[i] || {}, e[i]));
  return s;
}
function j(o, e, s) {
  V("generating back compat runtime config for %s", o);
  let t = Object.assign({}, e.supports);
  V("Supported capabilities in config before updating based on highestSupportedVersion: %o", t), Object.keys(s).forEach(e => {
    n(o, e) >= 0 && s[e].forEach(o => {
      void 0 !== i.hostClientType && o.hostClientTypes.includes(i.hostClientType) && (t = C(t, o.capability));
    });
  });
  const r = {
    apiVersion: d,
    hostVersionsInfo: p,
    isLegacyTeams: !0,
    supports: t
  };
  return V("Runtime config after updating based on highestSupportedVersion: %o", r), r;
}
const w = l.extend("applyRuntimeConfig");
function O(o) {
  "string" == typeof o.apiVersion && (w("Trying to apply runtime with string apiVersion, processing as v1: %o", o), o = Object.assign(Object.assign({}, o), {
    apiVersion: 1
  })), w("Fast-forwarding runtime %o", o);
  const e = h(o);
  w("Applying runtime %o", e), g = r(e);
}
export { O as applyRuntimeConfig, h as fastForwardRuntime, j as generateVersionBasedTeamsRuntimeConfig, c as isRuntimeInitialized, d as latestRuntimeApiVersion, T as mapTeamsVersionToSupportedCapabilities, g as runtime, v as upgradeChain, f as v1HostClientTypes, y as v1MobileHostClientTypes, m as versionAndPlatformAgnosticTeamsRuntimeConfig };