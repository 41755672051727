"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MessageRouter = void 0;
var ResponseItemType_1 = require("../Core/BotResult/ResponseItem/ResponseItemType");
var MessageRouter = /** @class */function () {
  function MessageRouter(messageSender) {
    this.messageSender = messageSender;
  }
  MessageRouter.prototype.send = function (responseItems, quickReplies, recipient, adapterSpecificData, messageToUpdateId) {
    return __awaiter(this, void 0, void 0, function () {
      var promise, _loop_1, i;
      var _this = this;
      return __generator(this, function (_a) {
        if (responseItems.length === 0 && (quickReplies !== undefined && quickReplies.length > 0 || adapterSpecificData !== undefined)) {
          throw new Error('Tried to send a message to the client where no ResponseItems were provided but QuickReplies or adapterSpecificData should be sent. This is not supported. Whenever QuickReplies or adapter specific data should be sent to the client, at least one IntentReaction or a prompt has to be provided.');
        }
        promise = Promise.resolve(undefined);
        _loop_1 = function (i) {
          var item = responseItems[i];
          var itemQuickReplies = i === responseItems.length - 1 ? quickReplies : undefined;
          promise = promise.then(function () {
            return _this.sendSingleItem(recipient, item, itemQuickReplies, adapterSpecificData, messageToUpdateId);
          });
        };
        for (i = 0; i < responseItems.length; i++) {
          _loop_1(i);
        }
        return [2 /*return*/, promise];
      });
    });
  };
  MessageRouter.prototype.sendSingleItem = function (recipient, item, quickReplies, adapterSpecificData, messageToUpdateId) {
    switch (item.type) {
      case ResponseItemType_1.ResponseItemType.ImageUrlItem:
        return this.messageSender.sendImage(recipient, item, quickReplies, adapterSpecificData, messageToUpdateId);
      case ResponseItemType_1.ResponseItemType.TextItem:
        return this.messageSender.sendText(recipient, item, quickReplies, adapterSpecificData, messageToUpdateId);
      case ResponseItemType_1.ResponseItemType.SelectionList:
        return this.messageSender.sendSelectionList(recipient, item, quickReplies, adapterSpecificData, messageToUpdateId);
      case ResponseItemType_1.ResponseItemType.MultiSelectionList:
        return this.messageSender.sendMultiSelectionList(recipient, item, quickReplies, adapterSpecificData, messageToUpdateId);
      case ResponseItemType_1.ResponseItemType.CardItem:
        return this.messageSender.sendCard(recipient, item, quickReplies, adapterSpecificData, messageToUpdateId);
      case ResponseItemType_1.ResponseItemType.LinksItem:
        return this.messageSender.sendLinks(recipient, item, quickReplies, adapterSpecificData, messageToUpdateId);
      case ResponseItemType_1.ResponseItemType.VerticalSelectionList:
        return this.messageSender.sendVerticalSelectionList(recipient, item, quickReplies, adapterSpecificData, messageToUpdateId);
      case ResponseItemType_1.ResponseItemType.AccountLinkingForm:
        return this.messageSender.sendAccountLinkingForm(recipient, item, quickReplies, adapterSpecificData, messageToUpdateId);
      case ResponseItemType_1.ResponseItemType.LocationRequestForm:
        return this.messageSender.sendLocationRequestForm(recipient, item, quickReplies, adapterSpecificData, messageToUpdateId);
      case ResponseItemType_1.ResponseItemType.NameRequestForm:
        return this.messageSender.sendNameRequestForm(recipient, item, quickReplies, adapterSpecificData, messageToUpdateId);
      case ResponseItemType_1.ResponseItemType.ClosingItem:
        return this.messageSender.sendClosing(recipient, item, adapterSpecificData, messageToUpdateId);
      default:
        return this.messageSender.sendCustom(recipient, item, quickReplies, adapterSpecificData, messageToUpdateId);
    }
  };
  return MessageRouter;
}();
exports.MessageRouter = MessageRouter;
