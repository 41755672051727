import { sendMessageToParent as t, Communication as r, sendMessageEventToChild as n } from "../../internal/communication.js";
import { registerHandler as e } from "../../internal/handlers.js";
import { ensureInitialized as a } from "../../internal/internalAPIs.js";
import { backStackNavigateBackHelper as o, pagesTelemetryVersionNumber as i } from "../../internal/pagesHelpers.js";
import { getApiVersionTag as s } from "../../internal/telemetry.js";
import { isNullOrUndefined as c } from "../../internal/typeCheckUtilities.js";
import { errorNotSupportedOnPlatform as p } from "../constants.js";
import { runtime as m } from "../runtime.js";
let u;
function l() {
  e(s(i, "pages.backStack.registerBackButtonPressHandler"), "backButtonPress", j, !1);
}
function f() {
  return o(s(i, "pages.backStack.navigateBack"));
}
function k(t) {
  g(s(i, "pages.backStack.registerBackButtonHandler"), t, () => {
    if (!c(t) && !B()) throw p;
  });
}
function g(r, n, e) {
  !c(n) && a(m), e && e(), u = n, !c(n) && t(r, "registerHandler", ["backButton"]);
}
function j() {
  u && u() || (r.childWindow ? n("backButtonPress", []) : f());
}
function B() {
  return !(!a(m) || !m.supports.pages) && !!m.supports.pages.backStack;
}
export { l as _initialize, B as isSupported, f as navigateBack, k as registerBackButtonHandler, g as registerBackButtonHandlerHelper };