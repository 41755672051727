import { ErrorCode as e } from "./interfaces.js";
var o, a, s, n, i, t, r;
!function (e) {
  e.desktop = "desktop", e.web = "web", e.android = "android", e.ios = "ios", e.ipados = "ipados", e.macos = "macos", e.rigel = "rigel", e.surfaceHub = "surfaceHub", e.teamsRoomsWindows = "teamsRoomsWindows", e.teamsRoomsAndroid = "teamsRoomsAndroid", e.teamsPhones = "teamsPhones", e.teamsDisplays = "teamsDisplays";
}(o || (o = {})), function (e) {
  e.office = "Office", e.outlook = "Outlook", e.outlookWin32 = "OutlookWin32", e.orange = "Orange", e.places = "Places", e.teams = "Teams", e.teamsModern = "TeamsModern";
}(a || (a = {})), function (e) {
  e.settings = "settings", e.content = "content", e.authentication = "authentication", e.remove = "remove", e.task = "task", e.sidePanel = "sidePanel", e.stage = "stage", e.meetingStage = "meetingStage";
}(s || (s = {})), function (e) {
  e[e.Standard = 0] = "Standard", e[e.Edu = 1] = "Edu", e[e.Class = 2] = "Class", e[e.Plc = 3] = "Plc", e[e.Staff = 4] = "Staff";
}(n || (n = {})), function (e) {
  e[e.Admin = 0] = "Admin", e[e.User = 1] = "User", e[e.Guest = 2] = "Guest";
}(i || (i = {})), function (e) {
  e.Large = "large", e.Medium = "medium", e.Small = "small";
}(t || (t = {})), function (e) {
  e.Regular = "Regular", e.Private = "Private", e.Shared = "Shared";
}(r || (r = {}));
const m = {
    errorCode: e.NOT_SUPPORTED_ON_PLATFORM
  },
  d = {
    majorVersion: 1,
    minorVersion: 5
  },
  u = {
    adaptiveCardSchemaVersion: {
      majorVersion: 1,
      minorVersion: 5
    }
  },
  l = new Error("Invalid input count: Must supply a valid image count (limit of 10)."),
  c = new Error("Invalid response: Received more images than the specified max limit in the response.");
export { r as ChannelType, t as DialogDimension, s as FrameContexts, o as HostClientType, a as HostName, t as TaskModuleDimension, n as TeamType, i as UserTeamRole, l as errorInvalidCount, c as errorInvalidResponse, m as errorNotSupportedOnPlatform, d as minAdaptiveCardVersion, u as teamsMinAdaptiveCardVersion };