import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {FrontendError} from './frontendError';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import * as StackTrace from 'stacktrace-js';
import {AuthenticationError} from '../authentication/authenticationError';
import {LIB_AUTH_SERVICE_TOKEN} from '../../../../../micromate-learn-lib/src/lib/components/chatbot/libAuthService';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public  handleError(rawError: any): void {
        // TODO MIC-2094
        void this.asyncHandleError(rawError);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private async asyncHandleError(rawError: any): Promise<void>  {
        const error: Error = rawError.rejection !== undefined ? rawError.rejection as Error : rawError as Error;

        const frontendError = await this.createFrontendErrorFromError(error);
        await this.sendError(frontendError);

        if (error instanceof AuthenticationError) {
            window.location.href = environment.authErrorUrl;
        }

        console.error(error);
    }

    private async createFrontendErrorFromError(error: Error): Promise<FrontendError> {
        const innerError: FrontendError = error.cause !== undefined && (error.cause instanceof Error || error.cause instanceof HttpErrorResponse) ? await this.createFrontendErrorFromError(error.cause) : undefined;
        let message = error.message.split('at ')[0];

        if (error instanceof HttpErrorResponse) {
            let headers = '';
            for (const key of error.headers.keys()) {
                headers += `${key}: ${error.headers.get(key)}\r\n`;
            }

            message += `\r\nError from server: ${error.error}`;
            message += `\r\nHeaders: \r\n${headers}`;
        }

        return new FrontendError(
            new Date(),
            message,
            await this.getStackTrace(error),
            innerError,
            window.location.href,
            this.detectBrowser(),
            this.injector?.get(LIB_AUTH_SERVICE_TOKEN)?.getAccessToken()
        );
    }

    private async sendError(frontendError: FrontendError): Promise<string> {
        const http = this.injector.get(HttpClient);
        return http.post(`${environment.serverUrl}/api/frontend-error`, frontendError, {responseType: 'text'}).toPromise();
    }

    private async getStackTrace(error: Error): Promise<string> {
        if (error.stack !== undefined) {
            const stackTraces = await StackTrace.fromError(error);
            if (stackTraces !== undefined) {
                const stack: string[] = [];
                for (const stackTrace of stackTraces) {
                    stack.push(`    at ${stackTrace.functionName} (${stackTrace.fileName}:${stackTrace.lineNumber}:${stackTrace.columnNumber})`);
                }
                return stack.join('\n');
            }
        }
        return 'undefined';
    }

    /* eslint-disable */
    private detectBrowser(): string {
        const userAgent = navigator.userAgent;
        let tem;
        let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(matchTest[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return `IE ${tem[1] || ''}`;
        }

        if (matchTest[1] === 'Chrome') {
            tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) {
                return tem.slice(1).join(' ').replace('OPR', 'Opera') as string;
            }
        }

        matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
        // tslint:disable-next-line:no-conditional-assignment
        if ((tem = userAgent.match(/version\/(\d+)/i)) != null) {
            matchTest.splice(1, 1, tem[1]);
        }
        return matchTest.join(' ');
    }

    /* eslint-enable */
}
