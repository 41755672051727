import { sendMessageToParent as o } from "../../../internal/communication.js";
import { storedMessages as r, dialogTelemetryVersionNumber as n } from "../../../internal/dialogHelpers.js";
import { removeHandler as e, registerHandler as i } from "../../../internal/handlers.js";
import { ensureInitialized as t } from "../../../internal/internalAPIs.js";
import { getApiVersionTag as a } from "../../../internal/telemetry.js";
import { FrameContexts as s, errorNotSupportedOnPlatform as m } from "../../constants.js";
import { runtime as l } from "../../runtime.js";
function d(r) {
  if (t(l, s.task), !p()) throw m;
  o(a(n, "dialog.url.parentCommunication.sendMessageToParentFromDialog"), "messageForParent", [r]);
}
function g(r) {
  if (t(l, s.content, s.sidePanel, s.meetingStage), !p()) throw m;
  o(a(n, "dialog.url.parentCommunication.sendMessageToDialog"), "messageForChild", [r]);
}
function u(o) {
  if (t(l, s.task), !p()) throw m;
  for (e("messageForChild"), i(a(n, "dialog.url.parentCommunication.registerMessageForChildHandler"), "messageForChild", o), r.reverse(); r.length > 0;) {
    o(r.pop());
  }
}
function p() {
  var o, r;
  return t(l) && !!(null === (r = null === (o = l.supports.dialog) || void 0 === o ? void 0 : o.url) || void 0 === r ? void 0 : r.parentCommunication);
}
export { p as isSupported, u as registerOnMessageFromParent, g as sendMessageToDialog, d as sendMessageToParentFromDialog };