import { sendMessageToParent as o } from "../../../internal/communication.js";
import { getDialogInfoFromBotAdaptiveCardDialogInfo as t, dialogTelemetryVersionNumber as r } from "../../../internal/dialogHelpers.js";
import { ensureInitialized as n } from "../../../internal/internalAPIs.js";
import { getApiVersionTag as s } from "../../../internal/telemetry.js";
import { isHostAdaptiveCardSchemaVersionUnsupported as i } from "../../../internal/utils.js";
import { FrameContexts as e, errorNotSupportedOnPlatform as a } from "../../constants.js";
import { runtime as m } from "../../runtime.js";
function p(i, p) {
  if (n(m, e.content, e.sidePanel, e.meetingStage), !l()) throw a;
  const d = t(i);
  o(s(r, "dialog.adaptiveCard.bot.open"), "tasks.startTask", [d], (o, t) => {
    null == p || p({
      err: o,
      result: t
    });
  });
}
function l() {
  const o = m.hostVersionsInfo && m.hostVersionsInfo.adaptiveCardSchemaVersion && !i(m.hostVersionsInfo.adaptiveCardSchemaVersion);
  return n(m) && void 0 !== (o && m.supports.dialog && m.supports.dialog.card && m.supports.dialog.card.bot);
}
export { l as isSupported, p as open };