import {Component, Inject, OnInit} from '@angular/core';
import {BotatoChatManagerService} from 'botato-angular-lib';
import {Observable} from 'rxjs';
import {LearnAuthService} from '../../../core/authentication/learn-auth.service';
import {ChatStartService} from '../../../../../../micromate-learn-lib/src/lib/components/chatbot/chat-start.service';
import {NavigationService} from '../../../core/navigation.service';
import {LIB_AUTH_SERVICE_TOKEN} from '../../../../../../micromate-learn-lib/src/lib/components/chatbot/libAuthService';
import {LoggerRessource} from '../../../../../../micromate-learn-lib/src/lib/services/rest/logger-ressource.service';

@Component({
    templateUrl: './learning-container.component.html',
    styleUrls: ['./learning-container.component.scss']
})
export class LearningContainerComponent implements OnInit {

    public alreadyConnected$: Observable<boolean>;
    public isConnected: boolean = false;
    public actionInProgress: boolean = false;

    constructor(private chatManagerService: BotatoChatManagerService,
                private navService: NavigationService,
                private chatStartService: ChatStartService,
                @Inject(LIB_AUTH_SERVICE_TOKEN) public authService: LearnAuthService,
                private logger: LoggerRessource) {
        this.alreadyConnected$ = this.chatManagerService.alreadyConnectChanged;
    }

    public ngOnInit(): void {
        // TODO MIC-2094
        void this.asyncNgOnInit();
    }

    private async asyncNgOnInit(): Promise<void> {
        this.chatManagerService.authenticationFailed$.subscribe(() => {
            this.logger.logFrontendInfoToBackend('Logout user as the websocket did not authenticate properly', this.authService.getAuthenticatedUser());
            this.authService.startLogoutFlow();
        });

        this.chatManagerService.setAccessTokenProvider(async () => {

            if (!this.authService.hasValidTokens()) {
                this.authService.startLogoutFlow();

                // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
                return new Promise((resolve) => {
                });
            }
            // TODO remove token log
            const accessToken = this.authService.getAccessToken();
            this.logger.logFrontendInfoToBackend(`Initialized websocket with token ${accessToken}`, this.authService.getAuthenticatedUser());
            return `Bearer ${accessToken}`;
        });

        this.isConnected = await this.chatManagerService.connect();
    }

    public async reconnect(): Promise<void> {
        this.actionInProgress = true;
        this.isConnected = await this.chatManagerService.connect(true);
        this.chatManagerService.clearDialog();
        this.chatStartService.setOpenState(true);
        await this.chatManagerService.start();
        this.navService.navigateToChat(false);
        this.actionInProgress = false;
    }
}
