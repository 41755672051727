import { sendMessageToParent as o } from "../../../internal/communication.js";
import { getDialogInfoFromAdaptiveCardDialogInfo as t, dialogTelemetryVersionNumber as r } from "../../../internal/dialogHelpers.js";
import { ensureInitialized as n } from "../../../internal/internalAPIs.js";
import { getApiVersionTag as s } from "../../../internal/telemetry.js";
import { isHostAdaptiveCardSchemaVersionUnsupported as i } from "../../../internal/utils.js";
import { FrameContexts as e, errorNotSupportedOnPlatform as a } from "../../constants.js";
import { runtime as m } from "../../runtime.js";
import * as p from "./bot.js";
export { p as bot };
function l(i, p) {
  if (n(m, e.content, e.sidePanel, e.meetingStage), !f()) throw a;
  const l = t(i);
  o(s(r, "dialog.adaptiveCard.open"), "tasks.startTask", [l], (o, t) => {
    null == p || p({
      err: o,
      result: t
    });
  });
}
function f() {
  const o = m.hostVersionsInfo && m.hostVersionsInfo.adaptiveCardSchemaVersion && !i(m.hostVersionsInfo.adaptiveCardSchemaVersion);
  return n(m) && void 0 !== (o && m.supports.dialog && m.supports.dialog.card);
}
export { f as isSupported, l as open };