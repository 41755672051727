import { __awaiter as e } from "../../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { requestPortFromParentWithVersion as t } from "../../internal/communication.js";
import { ensureInitialized as r } from "../../internal/internalAPIs.js";
import { getLogger as n, getApiVersionTag as o } from "../../internal/telemetry.js";
import { errorNotSupportedOnPlatform as i } from "../../public/constants.js";
import { runtime as s } from "../../public/runtime.js";
let l;
const m = n("messageChannels.telemetry");
function p() {
  return e(this, void 0, void 0, function* () {
    if (l) return m("Returning telemetry port from cache"), l;
    if (!u()) throw i;
    return l = yield t(o("v1", "messageChannels.telemetry.getTelemetryPort"), "messageChannels.telemetry.getTelemetryPort"), l;
  });
}
function u() {
  var e;
  return !(!r(s) || !(null === (e = s.supports.messageChannels) || void 0 === e ? void 0 : e.telemetry));
}
function a() {
  l = void 0;
}
export { a as _clearTelemetryPort, p as getTelemetryPort, u as isSupported };