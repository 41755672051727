import {Component, OnInit} from '@angular/core';
import {ImmediatelyRenderedMessageComponent} from 'botato-angular-lib';
import {ReflectionFreeTextExplanationResponseItemData} from './ReflectionFreeTextExplanationResponseItemData';

@Component({
    selector: 'mm-reflection-free-text-carousel-explanation',
    templateUrl: './reflection-free-text-carousel-explanation.component.html',
    styleUrls: []
})
export class ReflectionFreeTextCarouselExplanationComponent extends ImmediatelyRenderedMessageComponent implements OnInit {
    public static TYPE_SINGLE = 'ReflectionSingleExplanation';
    public static TYPE_CAROUSEL = 'ReflectionCarouselExplanation';
    public responseItemData: ReflectionFreeTextExplanationResponseItemData;
    public reflections: string[];
    public activeReflection = 1;
    public longestReflectionIndex: string;

    public ngOnInit(): void {

        this.responseItemData = this.message.data.responseItemData as ReflectionFreeTextExplanationResponseItemData;
        if (this.responseItemData?.message?.reflections === undefined) {
            throw new Error(`Undefined responseItemData in ReflectionFreeTextCarouselExplanation: ${JSON.stringify(this.responseItemData)}`);
        }
        this.reflections = this.responseItemData.message.reflections;
        this.longestReflectionIndex = this.reflections.reduce((a, b) => a.length > b.length ? a : b, '');
    }

    public showNextReflection(): void {
        this.activeReflection++;
    }

    public showPreviousReflection(): void {
        this.activeReflection--;
    }
}
