"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WebSocketServerResponseDtoCreator = void 0;
var AdapterConfiguration_1 = require("../../AdapterConfiguration");
var WebSocketServerResponseDto_1 = require("./WebSocketServerResponseDto");
var WebSocketServerResponseDtoCreator = /** @class */function () {
  function WebSocketServerResponseDtoCreator() {}
  WebSocketServerResponseDtoCreator.createTextMessage = function (data, quickReplies, webSocketSpecificData) {
    data.text = data.text.split(AdapterConfiguration_1.AdapterConfiguration.NEW_LINE_SEPARATOR).join('<br />');
    data.text = data.text.replace(AdapterConfiguration_1.AdapterConfiguration.LINK_REGEX, '<a href="$2" target="_blank" alt="$1">$1</a>');
    data.text = data.text.replace(AdapterConfiguration_1.AdapterConfiguration.BOLD_REGEX, '<strong>$1</strong>');
    return WebSocketServerResponseDtoCreator.createMessage(data, quickReplies, 'text', webSocketSpecificData);
  };
  WebSocketServerResponseDtoCreator.createSelectionListMessage = function (data, quickReplies, webSocketSpecificData) {
    return WebSocketServerResponseDtoCreator.createMessage(data, quickReplies, 'selectionlist', webSocketSpecificData);
  };
  WebSocketServerResponseDtoCreator.createMultiSelectionListMessage = function (data, quickReplies, webSocketSpecificData) {
    return WebSocketServerResponseDtoCreator.createMessage(data, quickReplies, 'multiselectionlist', webSocketSpecificData);
  };
  WebSocketServerResponseDtoCreator.createLinksMessage = function (data, quickReplies, webSocketSpecificData) {
    return WebSocketServerResponseDtoCreator.createMessage(data, quickReplies, 'links', webSocketSpecificData);
  };
  WebSocketServerResponseDtoCreator.createImageMessage = function (data, quickReplies, webSocketSpecificData) {
    return WebSocketServerResponseDtoCreator.createMessage(data, quickReplies, 'image', webSocketSpecificData);
  };
  WebSocketServerResponseDtoCreator.createCustomMessage = function (data, quickReplies, webSocketSpecificData) {
    return WebSocketServerResponseDtoCreator.createMessage(data, quickReplies, data.type, webSocketSpecificData);
  };
  WebSocketServerResponseDtoCreator.createMessage = function (data, quickReplies, type, webSocketSpecificData) {
    var message = new WebSocketServerResponseDto_1.WebSocketServerResponseDto();
    message.type = type;
    message.data = data;
    message.quickReplies = quickReplies;
    message.webSocketSpecificData = webSocketSpecificData;
    return message;
  };
  return WebSocketServerResponseDtoCreator;
}();
exports.WebSocketServerResponseDtoCreator = WebSocketServerResponseDtoCreator;
