import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResourceViewerService} from './resource-viewer.service';
import {
    EventRecorderService
} from '../../../../../../micromate-learn-lib/src/lib/services/event/event-recorder.service';
import {
    ResourceOpenedFromType
} from '../../../../../../micromate-learn-lib/src/lib/services/event/ResourceOpenEventPayload';
import {
    LIB_AUTH_SERVICE_TOKEN,
    LibAuthService
} from '../../../../../../micromate-learn-lib/src/lib/components/chatbot/libAuthService';
import {filter, take} from 'rxjs/operators';

@Component({
    selector: 'mm-resource-viewer',
    templateUrl: './resource-viewer.component.html',
    styleUrls: ['./resource-viewer.component.scss']
})
export class ResourceViewerComponent implements OnInit {

    constructor(public resourceViewerService: ResourceViewerService,
                private route: ActivatedRoute,
                private router: Router,
                private eventRecorderService: EventRecorderService,
                @Inject(LIB_AUTH_SERVICE_TOKEN) private authService: LibAuthService) {
    }

    public ngOnInit(): void {
        // TODO MIC-2094
        void this.asyncNgOnInit();
    }

    private async asyncNgOnInit(): Promise<void> {
        const resourceId = this.route.snapshot.params['resourceId'] as string;
        const selectionId = this.route.snapshot.params['selectionId'] as string;
        const viewAsMicromateHtml = this.route.snapshot.queryParams['format'] === 'micromate-html';
        await this.recordResourceViewerOpenEvent(resourceId, selectionId);
        this.resourceViewerService.loadResource(resourceId, selectionId, viewAsMicromateHtml);
    }

    private async recordResourceViewerOpenEvent(resourceId: string, selectionId: string): Promise<void> {
        this.authService.authentication$.pipe(filter(authentication => authentication !== undefined), take(1)).subscribe(async (authentication) => {
            const openedFrom = this.route.snapshot.queryParams['opened-from'] as string;
            if (openedFrom === ResourceOpenedFromType.QA_SOURCE || openedFrom === ResourceOpenedFromType.QUESTION) {
                await this.eventRecorderService.recordResourceViewerOpen(authentication.sub, ResourceOpenedFromType.QUESTION, resourceId, selectionId);
                await this.router.navigate([], {
                    queryParams: {
                        'opened-from': undefined,
                    }, queryParamsHandling: 'merge'
                });
            }
        });
    }

}
