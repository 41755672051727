"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResponseItemType = void 0;
var ResponseItemType;
(function (ResponseItemType) {
  ResponseItemType["TextItem"] = "TextItem";
  ResponseItemType["CardItem"] = "CardItem";
  ResponseItemType["LinksItem"] = "LinksItem";
  ResponseItemType["MultiSelectionList"] = "MultiSelectionList";
  ResponseItemType["SelectionList"] = "SelectionList";
  ResponseItemType["AccountLinkingForm"] = "AccountLinkingForm";
  ResponseItemType["ClosingItem"] = "ClosingItem";
  ResponseItemType["ImageUrlItem"] = "ImageUrlItem";
  ResponseItemType["LocationRequestForm"] = "LocationRequestForm";
  ResponseItemType["VerticalSelectionList"] = "VerticalSelectionList";
  ResponseItemType["NameRequestForm"] = "NameRequestForm";
})(ResponseItemType = exports.ResponseItemType || (exports.ResponseItemType = {}));
