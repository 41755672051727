import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LearnerIdQueryPrams} from './LearnerIdQueryPrams';
import {EnvironmentService} from '../EnvironmentService';
import {LearnerProfile} from '../../types/LearnerProfile';
import {Observable} from 'rxjs';
import {PseudonymSettings} from '../../components/profile/PseudonymSettings';

@Injectable({
    providedIn: 'root'
})
export class ProfileRessource {

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    }

    public getLearnerProfile(msteamsId: string): Promise<LearnerProfile> {
        return this.http.get<LearnerProfile>(`${this.environmentService.environment.serverUrl}/api/profile/profile?msteamsid=${msteamsId}`).toPromise();
    }

    public getLearnerProfileForLearn(learnerId: string, zitadelId: string): Promise<LearnerProfile> {
        return this.http.get<LearnerProfile>(`${this.environmentService.environment.serverUrl}/api/learn/profile/profile?${LearnerIdQueryPrams.create(learnerId, zitadelId)}`).toPromise();
    }

    public getAdminLearnerProfileForLearn(learnerId: string): Promise<LearnerProfile> {
        return this.http.get<LearnerProfile>(`${this.environmentService.environment.serverUrl}/api/learn/admin/profile?learnerid=${learnerId}`).toPromise();
    }

    public savePseudonymSettings(msteamsid: string, pseudonymSettings: PseudonymSettings): Observable<string> {
        return this.http.post(`${this.environmentService.environment.serverUrl}/api/profile/profile/pseudonym-settings?msteamsid=${msteamsid}`, pseudonymSettings, {responseType: 'text'});
    }

    public savePseudonymSettingsForLearn(zitadelId: string, pseudonymSettings: PseudonymSettings): Observable<string> {
        return this.http.post(`${this.environmentService.environment.serverUrl}/api/learn/profile/profile/pseudonym-settings?zitadelid=${zitadelId}`, pseudonymSettings, {responseType: 'text'});
    }

    public generatePseudonym(msteamsid: string): Observable<string> {
        return this.http.get(`${this.environmentService.environment.serverUrl}/api/profile/profile/pseudonym?msteamsid=${msteamsid}`, {responseType: 'text'});
    }

    public generatePseudonymForLearn(zitadelId: string): Observable<string> {
        return this.http.get(`${this.environmentService.environment.serverUrl}/api/learn/profile/profile/pseudonym?zitadelid=${zitadelId}`, {responseType: 'text'});
    }

    public saveMonthlyLearningReportStatus(msteamsid: string, monthlyLearningReportStatus: boolean): Observable<string> {
        return this.http.post(`${this.environmentService.environment.serverUrl}/api/profile/profile/monthly-learning-report-state?msteamsid=${msteamsid}`, {monthlyLearningReportStatus: monthlyLearningReportStatus}, {responseType: 'text'});
    }

    public saveMonthlyLearningReportStatusForLearn(zitadelId: string, monthlyLearningReportStatus: boolean): Observable<string> {
        return this.http.post(`${this.environmentService.environment.serverUrl}/api/learn/profile/profile/monthly-learning-report-state?zitadelid=${zitadelId}`, {monthlyLearningReportStatus: monthlyLearningReportStatus}, {responseType: 'text'});
    }

    public updateProfileForLearn(learnerId: string, zitadelId: string, profileData: LearnerProfile): Promise<LearnerProfile> {
        return this.http.post<LearnerProfile>(`${this.environmentService.environment.serverUrl}/api/learn/profile/profile?${LearnerIdQueryPrams.create(learnerId, zitadelId)}`, profileData).toPromise();
    }

    public updateProfileForTeams(msteamsId: string, profileData: LearnerProfile): Promise<LearnerProfile> {
        return this.http.post<LearnerProfile>(`${this.environmentService.environment.serverUrl}/api/profile/profile?msteamsid=${msteamsId}`, profileData).toPromise();
    }
}
