import { __awaiter as t } from "../../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { requestPortFromParentWithVersion as r } from "../../internal/communication.js";
import { ensureInitialized as e } from "../../internal/internalAPIs.js";
import { getLogger as n, getApiVersionTag as o } from "../../internal/telemetry.js";
import { errorNotSupportedOnPlatform as a } from "../../public/constants.js";
import { runtime as i } from "../../public/runtime.js";
let s;
const l = n("messageChannels.dataLayer");
function m() {
  return t(this, void 0, void 0, function* () {
    if (s) return l("Returning dataLayer port from cache"), s;
    if (!p()) throw a;
    return s = yield r(o("v1", "messageChannels.dataLayer.getDataLayerPort"), "messageChannels.dataLayer.getDataLayerPort"), s;
  });
}
function p() {
  var t;
  return !(!e(i) || !(null === (t = i.supports.messageChannels) || void 0 === t ? void 0 : t.dataLayer));
}
function u() {
  s = void 0;
}
export { u as _clearDataLayerPort, m as getDataLayerPort, p as isSupported };