import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {DelayedRenderedMessageComponent} from 'botato-angular-lib';
import {QuizResponseItemData} from '../../type/QuizResponseItemData';
import {FreeTextQuizResponseItemDataText} from './FreeTextQuizResponseItemDataText';
import {FreeTextQuiz} from './FreeTextQuiz';
import {DeactivateMessageService} from '../../../deactivate-message.service';
import {Subscription} from 'rxjs';
import {FreeTextQuizUpdateAnswer} from './FreeTextQuizUpdateAnswer';
import {QuizQuestion} from '../../type/QuizQuestion';

@Component({
    selector: 'mm-free-text-quiz',
    templateUrl: './free-text-quiz.component.html'
})
export class FreeTextQuizComponent extends DelayedRenderedMessageComponent implements OnInit, OnDestroy {

    public static TYPE = 'FreeText';
    public static UPDATE_TYPE = 'updateFreeText';

    public responseItemData: QuizResponseItemData<FreeTextQuiz, FreeTextQuizResponseItemDataText, FreeTextQuizUpdateAnswer>;
    public question: QuizQuestion;
    public quizNumber: string;
    public isDeactivated: boolean;
    private subscription: Subscription;
    public updatedMessage = false;
    public sourceUrl: string;
    public sourceLabel: string;
    public questionTranslationId: string;
    public learningPackageTranslationId: string;
    public shouldShowMessage: boolean = false;

    constructor(private deactivateMessageService: DeactivateMessageService,
                private changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    public ngOnDestroy(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }

    public ngOnInit(): void {
        if (this.message.type === FreeTextQuizComponent.TYPE) {
            this.shouldShowMessage = true;

            this.updatedMessage = this.isMessageUpdated();
            if (this.updatedMessage) {
                this.createMessageUpdateData();
            } else {
                this.subscription = this.deactivateMessageService.deactivateOldCustomMessage(this.message.data);

                this.isDeactivated = this.message.data.deactivated !== undefined ? this.message.data.deactivated : false;

                this.createMessageData();
            }
        }

        if (this.message.type === FreeTextQuizComponent.UPDATE_TYPE) {
            this.subscription = this.deactivateMessageService.setUpdateOfLastMessage(this.message.data, this.changeDetectorRef);
        }
    }

    public isMessageUpdated(): boolean {

        return this.message.data.updateMessage?.responseItemData !== undefined;
    }

    public mediaLoaded(): void {
        this.componentRenderingCompleted();
    }

    private createMessageData(): void {

        this.responseItemData = this.message.data.responseItemData;
        this.quizNumber = this.responseItemData.learningSessionData.quizNumber;
        this.question = this.responseItemData.quiz.quiz;
    }

    private createMessageUpdateData(): void {

        this.responseItemData = this.message.data.updateMessage.responseItemData;
        this.question = this.responseItemData.quizAnswerData.quiz;
        this.quizNumber = this.responseItemData.quizAnswerData.quizNumber;
        this.sourceUrl = this.responseItemData.quizAnswerData.sourceUrl;
        this.sourceLabel = this.responseItemData.quizAnswerData.sourceLabel;
        this.questionTranslationId = this.responseItemData.quizAnswerData.id;
        this.learningPackageTranslationId = this.responseItemData.learningPackageTranslationId;
    }

    public override onAnyMessageSent(): void {
        if (!this.isDeactivated) {

            this.message.data.deactivated = true;
        }
    }
}
