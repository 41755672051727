"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Keyword = void 0;
var Keyword = /** @class */function () {
  function Keyword(values, intentName, requiredContext, intentGroup) {
    if (requiredContext === void 0) {
      requiredContext = [];
    }
    this.values = values;
    this.intentName = intentName;
    this.requiredContext = requiredContext;
    this.intentGroup = intentGroup;
  }
  return Keyword;
}();
exports.Keyword = Keyword;
