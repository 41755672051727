import { __awaiter as e } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { getLogger as n, getApiVersionTag as r } from "./telemetry.js";
import { runtime as l } from "../public/runtime.js";
import { sendMessageToParent as o, Communication as a, sendMessageEventToChild as d } from "./communication.js";
import { ensureInitialized as t } from "./internalAPIs.js";
import { isNullOrUndefined as i } from "./typeCheckUtilities.js";
import { _initialize as s } from "../public/pages/backStack.js";
const u = n("handlers");
class c {
  static initializeHandlers() {
    c.handlers.themeChange = v, c.handlers.load = j, c.handlers.beforeUnload = S, s();
  }
  static uninitializeHandlers() {
    c.handlers = {}, c.themeChangeHandler = null, c.loadHandler = null, c.beforeUnloadHandler = null, c.beforeSuspendOrTerminateHandler = null, c.resumeHandler = null;
  }
}
function f() {
  c.initializeHandlers();
}
function m() {
  c.uninitializeHandlers();
}
c.handlers = {}, c.themeChangeHandler = null, c.loadHandler = null, c.beforeUnloadHandler = null, c.beforeSuspendOrTerminateHandler = null, c.resumeHandler = null, c.hostToAppPerformanceMetricsHandler = null;
const h = u.extend("callHandler");
function H(e, n) {
  const r = c.handlers[e];
  if (r) {
    h("Invoking the registered handler for message %s with arguments %o", e, n);
    return [!0, r.apply(this, n)];
  }
  return a.childWindow ? (d(e, n), [!1, void 0]) : (h("Handler for action message %s not found.", e), [!1, void 0]);
}
function p(e, n, r, l = !0, a = []) {
  r ? (c.handlers[n] = r, l && o(e, "registerHandler", [n, ...a])) : delete c.handlers[n];
}
function g(e) {
  delete c.handlers[e];
}
function b(e) {
  return null != c.handlers[e];
}
function U(e, n, r, o, a) {
  r && t(l, ...o), a && a(), p(e, n, r);
}
function T(e, n) {
  c.themeChangeHandler = n, !i(n) && o(e, "registerHandler", ["themeChange"]);
}
function v(e) {
  c.themeChangeHandler && c.themeChangeHandler(e), a.childWindow && d("themeChange", [e]);
}
function y(e) {
  c.hostToAppPerformanceMetricsHandler = e;
}
function C(e) {
  c.hostToAppPerformanceMetricsHandler && c.hostToAppPerformanceMetricsHandler(e);
}
function w(e, n) {
  c.loadHandler = n, !i(n) && o(e, "registerHandler", ["load"]);
}
function j(e) {
  const n = {
    entityId: (r = e).entityId,
    contentUrl: new URL(r.contentUrl)
  };
  var r;
  c.resumeHandler ? (c.resumeHandler(n), a.childWindow && d("load", [n])) : c.loadHandler && (c.loadHandler(e), a.childWindow && d("load", [e]));
}
function O(e, n) {
  c.beforeUnloadHandler = n, !i(n) && o(e, "registerHandler", ["beforeUnload"]);
}
function S() {
  return e(this, void 0, void 0, function* () {
    const e = () => {
      o(r("v2", "handleBeforeUnload"), "readyToUnload", []);
    };
    c.beforeSuspendOrTerminateHandler ? (yield c.beforeSuspendOrTerminateHandler(), a.childWindow ? d("beforeUnload") : e()) : c.beforeUnloadHandler && c.beforeUnloadHandler(e) || (a.childWindow ? d("beforeUnload") : e());
  });
}
function W(e) {
  c.beforeSuspendOrTerminateHandler = e, !i(e) && o(r("v2", "registerBeforeSuspendOrTerminateHandler"), "registerHandler", ["beforeUnload"]);
}
function _(e) {
  c.resumeHandler = e, !i(e) && o(r("v2", "registerOnResumeHandler"), "registerHandler", ["load"]);
}
export { H as callHandler, b as doesHandlerExist, C as handleHostToAppPerformanceMetrics, v as handleThemeChange, f as initializeHandlers, W as registerBeforeSuspendOrTerminateHandler, O as registerBeforeUnloadHandler, p as registerHandler, U as registerHandlerHelper, y as registerHostToAppPerformanceMetricsHandler, w as registerOnLoadHandler, _ as registerOnResumeHandler, T as registerOnThemeChangeHandler, g as removeHandler, m as uninitializeHandlers };