import { sendMessageToParent as e, Communication as t, sendMessageEventToChild as i } from "../../internal/communication.js";
import { registerHandler as n, registerHandlerHelper as s } from "../../internal/handlers.js";
import { ensureInitialized as o } from "../../internal/internalAPIs.js";
import { configSetValidityStateHelper as r, configSetConfigHelper as f, pagesTelemetryVersionNumber as a } from "../../internal/pagesHelpers.js";
import { getApiVersionTag as c } from "../../internal/telemetry.js";
import { isNullOrUndefined as g } from "../../internal/typeCheckUtilities.js";
import { FrameContexts as u, errorNotSupportedOnPlatform as l } from "../constants.js";
import { runtime as m } from "../runtime.js";
let p, d;
function v() {
  n(c(a, "pages.config.registerSettingsSaveHandler"), "settings.save", E, !1), n(c(a, "pages.config.registerSettingsRemoveHandler"), "settings.remove", F, !1);
}
function h(e) {
  return r(c(a, "pages.config.setValidityState"), e);
}
function y(e) {
  return f(c(a, "pages.config.setConfig"), e);
}
function S(e) {
  N(c(a, "pages.config.registerOnSaveHandler"), e, () => {
    if (!g(e) && !O()) throw l;
  });
}
function N(t, i, n) {
  !g(i) && o(m, u.settings), n && n(), p = i, !g(i) && e(t, "registerHandler", ["save"]);
}
function w(e) {
  j(c(a, "pages.config.registerOnRemoveHandler"), e, () => {
    if (!g(e) && !O()) throw l;
  });
}
function j(t, i, n) {
  !g(i) && o(m, u.remove, u.settings), n && n(), d = i, !g(i) && e(t, "registerHandler", ["remove"]);
}
function E(e) {
  const n = new C(e);
  p ? p(n) : t.childWindow ? i("settings.save", [e]) : n.notifySuccess();
}
function H(e) {
  s(c(a, "pages.config.registerChangeConfigHandler"), "changeSettings", e, [u.content], () => {
    if (!O()) throw l;
  });
}
class C {
  constructor(e) {
    this.notified = !1, this.result = e || {};
  }
  notifySuccess() {
    this.ensureNotNotified(), e(c(a, "pages.saveEvent.notifySuccess"), "settings.save.success"), this.notified = !0;
  }
  notifyFailure(t) {
    this.ensureNotNotified(), e(c(a, "pages.saveEvent.notifyFailure"), "settings.save.failure", [t]), this.notified = !0;
  }
  ensureNotNotified() {
    if (this.notified) throw new Error("The SaveEvent may only notify success or failure once.");
  }
}
function F() {
  const e = new T();
  d ? d(e) : t.childWindow ? i("settings.remove", []) : e.notifySuccess();
}
class T {
  constructor() {
    this.notified = !1;
  }
  notifySuccess() {
    this.ensureNotNotified(), e(c(a, "pages.removeEvent.notifySuccess"), "settings.remove.success"), this.notified = !0;
  }
  notifyFailure(t) {
    this.ensureNotNotified(), e(c(a, "pages.removeEvent.notifyFailure"), "settings.remove.failure", [t]), this.notified = !0;
  }
  ensureNotNotified() {
    if (this.notified) throw new Error("The removeEventType may only notify success or failure once.");
  }
}
function O() {
  return !(!o(m) || !m.supports.pages) && !!m.supports.pages.config;
}
export { v as initialize, O as isSupported, H as registerChangeConfigHandler, w as registerOnRemoveHandler, j as registerOnRemoveHandlerHelper, S as registerOnSaveHandler, N as registerOnSaveHandlerHelper, y as setConfig, h as setValidityState };