import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {LearnerProfile} from '../../../../../micromate-learn-lib/src/lib/types/LearnerProfile';
import {filter, map, switchMap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {LearnAuthService} from '../../core/authentication/learn-auth.service';
import {AuthUntilUserRoleService} from '../../core/authentication/auth-until-user-role.service';
import {EventRecorderService} from '../../../../../micromate-learn-lib/src/lib/services/event/event-recorder.service';
import {ProfileRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/profile-ressource.service';
import {TabEventType} from '../../../../../micromate-learn-lib/src/lib/services/event/TabEventType';
import {NavigationService} from '../../core/navigation.service';
import {User} from '../../../../../micromate-learn-lib/src/lib/components/chatbot/User';
import {ProfilePseudonymService} from '../../../../../micromate-learn-lib/src/lib/components/profile/profile-pseudonym.service';
import {PseudonymSettings} from '../../../../../micromate-learn-lib/src/lib/components/profile/PseudonymSettings';
import {LIB_AUTH_SERVICE_TOKEN} from '../../../../../micromate-learn-lib/src/lib/components/chatbot/libAuthService';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {UILocalizationService} from '../../../../../micromate-learn-lib/src/lib/services/locale/u-i-localization.service';

@Component({
    selector: 'mm-learning-profile',
    templateUrl: './learning-profile.component.html',
    styleUrls: ['./learning-profile.component.scss']
})
export class LearningProfileComponent implements OnInit, OnDestroy {
    public profileData: BehaviorSubject<LearnerProfile> = new BehaviorSubject<LearnerProfile>(undefined);
    public profileData$ = this.profileData.asObservable();

    public locale: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    public locale$ = this.locale.asObservable();

    private profileUpdated: Subject<void> = new Subject<void>();
    public profileUpdated$: Observable<void> = this.profileUpdated.asObservable();

    public paramsSubscription = new Subscription();
    public openInConversation: boolean;
    public learnerId: string;
    private zitadelId: string;

    constructor(private activatedRoute: ActivatedRoute,
                private profileService: ProfileRessource,
                @Inject(LIB_AUTH_SERVICE_TOKEN) public authService: LearnAuthService,
                public authUntilUserRoleService: AuthUntilUserRoleService,
                private router: Router,
                private eventRecorderService: EventRecorderService,
                private navigationService: NavigationService,
                private uiLocalizationService: UILocalizationService,
                private profilePseudonymService: ProfilePseudonymService,
                private toastr: ToastrService,
                private translate: TranslateService) {
    }

    public ngOnInit(): void {
        this.paramsSubscription = this.activatedRoute.queryParamMap.pipe(
            map(params => {
                this.learnerId = params.get('learnerid') ?? undefined;
                this.openInConversation = params.get('openInConversation') === 'true';
            }),
            switchMap(() => this.authService.authentication$),
            filter((authentication: User) => authentication !== undefined))
            .subscribe(async (authentication: User) => {
                await this.getLearnerProfile(authentication);
                await this.recordTabEvents(authentication);
            });
    }

    public ngOnDestroy(): void {
        this.paramsSubscription.unsubscribe();
    }

    public async close(): Promise<void> {
        this.navigationService.closeProfile();
    }

    public savePseudonymSettings(pseudonymSettings: PseudonymSettings): void {
        this.profileService.savePseudonymSettingsForLearn(this.zitadelId, pseudonymSettings).subscribe(async () => {
            const profilePromise = this.profileService.getLearnerProfileForLearn(undefined, this.zitadelId);
            const profileData = await profilePromise;
            this.profileData.next(profileData);

        });
    }

    public async saveProfileData(data: LearnerProfile): Promise<void> {
        const updatedProfileData = await this.profileService.updateProfileForLearn(undefined, this.zitadelId, data);
        await this.uiLocalizationService.setLanguage(updatedProfileData.locale.split('-')[0]);
        await this.authService.updateCurrentUser();
        this.locale.next(updatedProfileData.locale);
        this.profileUpdated.next();
    }

    public generatePseudonym(): void {
        this.profilePseudonymService.pseudonymGenerationSubscription = this.profileService.generatePseudonymForLearn(this.zitadelId).subscribe(pseudonym =>
            this.profilePseudonymService.setNewPseudonym(pseudonym));
    }

    public changeMonthlyReport(monthlyReportState: boolean): void {
        this.profileService.saveMonthlyLearningReportStatusForLearn(this.zitadelId, monthlyReportState).subscribe(async () => {
            const profilePromise = this.profileService.getLearnerProfileForLearn(undefined, this.zitadelId);
            this.profileData.next(await profilePromise);
            this.toastr.success(this.translate.instant('profile.monthlyReportSaved') as string);
        });
    }

    private async getLearnerProfile(authentication: User): Promise<void> {
        if (this.learnerId === undefined) {
            this.zitadelId = authentication.sub;
            const profilePromise = this.profileService.getLearnerProfileForLearn(undefined, this.zitadelId);
            const profileData = await profilePromise;
            this.profileData.next(profileData);
            this.locale.next(profileData.locale);
        } else {
            const isAdmin = await this.authUntilUserRoleService.assertAdmin().toPromise();
            if (!isAdmin) {

                this.router.navigate(['/learn']);
            } else {
                const profileData = await this.profileService.getAdminLearnerProfileForLearn(this.learnerId);
                this.profileData.next(profileData);
                this.locale.next(profileData.locale);
            }
        }
    }

    private async recordTabEvents(authentication: User): Promise<void> {
        await this.eventRecorderService.recordTabOpenedEventForLearn(this.learnerId, authentication.sub, TabEventType.TabProfileOpened, this.eventRecorderService.createTabOpenEventPayloadForLearn(this.openInConversation));
    }


}
