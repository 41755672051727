import { sendMessageToParentAsync as t, Communication as e, sendMessageEventToChild as n, sendMessageToParent as i, waitForMessageQueue as r } from "../internal/communication.js";
import { GlobalVars as a } from "../internal/globalVars.js";
import { registerHandler as o, removeHandler as c } from "../internal/handlers.js";
import { ensureInitialized as l, ensureInitializeCalled as u } from "../internal/internalAPIs.js";
import { getApiVersionTag as s } from "../internal/telemetry.js";
import { fullyQualifyUrlString as h, validateUrl as d } from "../internal/utils.js";
import { FrameContexts as f, HostClientType as w } from "./constants.js";
import { runtime as m } from "./runtime.js";
const g = "v1",
  b = "v2";
let W, C, k;
function p() {
  o(s(g, "authentication.registerAuthenticateSuccessHandler"), "authentication.authenticate.success", P, !1), o(s(g, "authentication.registerAuthenticateFailureHandler"), "authentication.authenticate.failure", I, !1);
}
function v(t) {
  k = t;
}
function y(i) {
  const r = void 0 !== i,
    c = r ? i : k;
  if (!c) throw new Error("No parameters are provided for authentication");
  l(m, f.content, f.sidePanel, f.settings, f.remove, f.task, f.stage, f.meetingStage);
  return function (i, r) {
    return new Promise((c, l) => {
      if (a.hostClientType !== w.web) {
        const e = h(r.url);
        d(e), c(t(i, "authentication.authenticate", [e.href, r.width, r.height, r.isExternal]).then(([t, e]) => {
          if (t) return e;
          throw new Error(e);
        }));
      } else W = {
        success: c,
        fail: l
      }, function (t) {
        E();
        let i = t.width || 600,
          r = t.height || 400;
        i = Math.min(i, e.currentWindow.outerWidth - 400), r = Math.min(r, e.currentWindow.outerHeight - 200);
        const c = h(t.url.replace("{oauthRedirectMethod}", "web"));
        d(c);
        let l = void 0 !== e.currentWindow.screenLeft ? e.currentWindow.screenLeft : e.currentWindow.screenX,
          u = void 0 !== e.currentWindow.screenTop ? e.currentWindow.screenTop : e.currentWindow.screenY;
        l += e.currentWindow.outerWidth / 2 - i / 2, u += e.currentWindow.outerHeight / 2 - r / 2, e.childWindow = e.currentWindow.open(c.href, "_blank", "toolbar=no, location=yes, status=no, menubar=no, scrollbars=yes, top=" + u + ", left=" + l + ", width=" + i + ", height=" + r), e.childWindow ? (A(), C = e.currentWindow.setInterval(() => {
          if (!e.childWindow || e.childWindow.closed) I("CancelledByUser");else {
            const t = e.childOrigin;
            try {
              e.childOrigin = "*", n("ping");
            } finally {
              e.childOrigin = t;
            }
          }
        }, 100), o(s(g, "authentication.authenticationWindow.registerInitializeHandler"), "initialize", () => [f.authentication, a.hostClientType]), o(s(g, "authentication.authenticationWindow.registerNavigateCrossDomainHandler"), "navigateCrossDomain", () => !1)) : I("FailedToOpenWindow");
      }(r);
    });
  }(c.successCallback || c.failureCallback ? s(g, "authentication.authenticate") : s(b, "authentication.authenticate"), c).then(t => {
    try {
      return c && c.successCallback ? (c.successCallback(t), "") : t;
    } finally {
      r || (k = void 0);
    }
  }).catch(t => {
    try {
      if (c && c.failureCallback) return c.failureCallback(t.message), "";
      throw t;
    } finally {
      r || (k = void 0);
    }
  });
}
function T(e) {
  u();
  return function (e, n) {
    return new Promise(i => {
      i(t(e, "authentication.getAuthToken", [null == n ? void 0 : n.resources, null == n ? void 0 : n.claims, null == n ? void 0 : n.silent, null == n ? void 0 : n.tenantId]));
    }).then(([t, e]) => {
      if (t) return e;
      throw new Error(e);
    });
  }(e && (e.successCallback || e.failureCallback) ? s(g, "authentication.getAuthToken") : s(b, "authentication.getAuthToken"), e).then(t => e && e.successCallback ? (e.successCallback(t), "") : t).catch(t => {
    if (e && e.failureCallback) return e.failureCallback(t.message), "";
    throw t;
  });
}
function j(e) {
  u();
  return function (e) {
    return new Promise(n => {
      n(t(e, "authentication.getUser"));
    }).then(([t, e]) => {
      if (t) return e;
      throw e;
    });
  }(e && (e.successCallback || e.failureCallback) ? s(g, "authentication.getUser") : s(b, "authentication.getUser")).then(t => e && e.successCallback ? (e.successCallback(t), null) : t).catch(t => {
    const n = `Error returned, code = ${t.errorCode}, message = ${t.message}`;
    if (e && e.failureCallback) return e.failureCallback(n), null;
    throw new Error(n);
  });
}
function E() {
  A();
  try {
    e.childWindow && e.childWindow.close();
  } finally {
    e.childWindow = null, e.childOrigin = null;
  }
}
function A() {
  C && (clearInterval(C), C = 0), c("initialize"), c("navigateCrossDomain");
}
function H(t, n) {
  l(m, f.authentication);
  const a = s(n ? g : b, "authentication.notifySuccess");
  i(a, "authentication.authenticate.success", [t]), r(e.parentWindow, () => setTimeout(() => e.currentWindow.close(), 200));
}
function O(t, n) {
  l(m, f.authentication);
  const a = s(n ? g : b, "authentication.notifyFailure");
  i(a, "authentication.authenticate.failure", [t]), r(e.parentWindow, () => setTimeout(() => e.currentWindow.close(), 200));
}
function P(t) {
  try {
    W && W.success(t);
  } finally {
    W = void 0, E();
  }
}
function I(t) {
  try {
    W && W.fail(new Error(t));
  } finally {
    W = void 0, E();
  }
}
var U;
!function (t) {
  t.Public = "public", t.EUDB = "eudb", t.Other = "other";
}(U || (U = {}));
export { U as DataResidency, y as authenticate, T as getAuthToken, j as getUser, p as initialize, O as notifyFailure, H as notifySuccess, v as registerAuthenticationHandlers };