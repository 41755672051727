"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WebSocketServerResponseDto = void 0;
var WebSocketServerResponseDto = /** @class */function () {
  function WebSocketServerResponseDto() {}
  return WebSocketServerResponseDto;
}();
exports.WebSocketServerResponseDto = WebSocketServerResponseDto;
