import { AppId as t } from "../public/appId.js";
import { FrameContexts as e, errorNotSupportedOnPlatform as n } from "../public/constants.js";
import { isSupported as i } from "../public/pages/pages.js";
import { runtime as r } from "../public/runtime.js";
import { sendAndHandleStatusAndReasonWithDefaultError as o, sendMessageToParent as s, sendAndUnwrap as a, sendAndHandleStatusAndReason as c } from "./communication.js";
import { ensureInitialized as p } from "./internalAPIs.js";
import { isSupported as u } from "../public/pages/backStack.js";
import { isSupported as g } from "../public/pages/tabs.js";
import { isSupported as f } from "../public/pages/config.js";
const m = "v2";
function b(t, s) {
  return new Promise(a => {
    if (p(r, e.content, e.sidePanel, e.settings, e.remove, e.task, e.stage, e.meetingStage), !i()) throw n;
    a(o(t, "navigateCrossDomain", "Cross-origin navigation is only supported for URLs matching the pattern registered in the manifest.", s));
  });
}
function w(t) {
  return new Promise(e => {
    if (p(r), !u()) throw n;
    e(o(t, "navigateBack", "Back navigation is not supported in the current client or context."));
  });
}
function d(t, e) {
  return new Promise(i => {
    if (p(r), !g()) throw n;
    i(o(t, "navigateToTab", "Invalid internalTabInstanceId and/or channelId were/was provided", e));
  });
}
function l(t, e) {
  if (p(r), !i()) throw n;
  s(t, "returnFocus", [e]);
}
function h(t, e) {
  return new Promise(i => {
    if (p(r), !g()) throw n;
    i(a(t, "getTabInstances", e));
  });
}
function P(t, e) {
  return new Promise(i => {
    if (p(r), !g()) throw n;
    i(a(t, "getMruTabInstances", e));
  });
}
function I(t, o) {
  if (p(r, e.content, e.sidePanel, e.meetingStage), !i()) throw n;
  s(t, "shareDeepLink", [o.subPageId, o.subPageLabel, o.subPageWebUrl]);
}
function j(t, o) {
  if (p(r, e.content), !i()) throw n;
  s(t, "setFrameContext", [o]);
}
function v(t, i) {
  if (p(r, e.settings, e.remove), !f()) throw n;
  s(t, "settings.setValidityState", [i]);
}
function S(t) {
  return new Promise(o => {
    if (p(r, e.content, e.settings, e.remove, e.sidePanel), !i()) throw n;
    o(a(t, "settings.getSettings"));
  });
}
function U(t, i) {
  return new Promise(o => {
    if (p(r, e.content, e.settings, e.sidePanel), !f()) throw n;
    o(c(t, "settings.setSettings", i));
  });
}
function k(e) {
  return e.appId instanceof t;
}
function T(e) {
  return Object.assign(Object.assign({}, e), {
    appId: new t(e.appId),
    webUrl: e.webUrl ? new URL(e.webUrl) : void 0
  });
}
function L(t) {
  return Object.assign(Object.assign({}, t), {
    appId: t.appId.toString(),
    webUrl: t.webUrl ? t.webUrl.toString() : void 0
  });
}
export { w as backStackNavigateBackHelper, U as configSetConfigHelper, v as configSetValidityStateHelper, L as convertAppNavigationParametersToNavigateToAppParams, T as convertNavigateToAppParamsToAppNavigationParameters, S as getConfigHelper, P as getMruTabInstancesHelper, h as getTabInstancesHelper, k as isAppNavigationParametersObject, b as navigateCrossDomainHelper, m as pagesTelemetryVersionNumber, l as returnFocusHelper, j as setCurrentFrameHelper, I as shareDeepLinkHelper, d as tabsNavigateToTabHelper };