"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KeywordRecognzierIntepretation = void 0;
var KeywordRecognzierIntepretation = /** @class */function () {
  function KeywordRecognzierIntepretation(processedText, matchedIntentName, isFallbackFlag, language, inputSource, intentGroup) {
    this.processedText = processedText;
    this.matchedIntentName = matchedIntentName;
    this.isFallbackFlag = isFallbackFlag;
    this.language = language;
    this.inputSource = inputSource;
    this.intentGroup = intentGroup;
  }
  KeywordRecognzierIntepretation.prototype.getAllEntities = function () {
    // KeywordRecognizer does not support entities
    return {};
  };
  KeywordRecognzierIntepretation.prototype.getEntities = function (entityName) {
    // KeywordRecognizer does not support entities
    return [];
  };
  KeywordRecognzierIntepretation.prototype.getEntity = function (entityName) {
    // KeywordRecognizer does not support entities
    return undefined;
  };
  KeywordRecognzierIntepretation.prototype.getErrorMessage = function () {
    return undefined;
  };
  KeywordRecognzierIntepretation.prototype.getIntentName = function () {
    return this.matchedIntentName;
  };
  KeywordRecognzierIntepretation.prototype.getIntentGroup = function () {
    return this.intentGroup;
  };
  KeywordRecognzierIntepretation.prototype.getLocationEntity = function (entityName) {
    // KeywordRecognizer does not support entities
    return undefined;
  };
  KeywordRecognzierIntepretation.prototype.getMatchingScore = function () {
    return 1;
  };
  KeywordRecognzierIntepretation.prototype.getPeriodEntity = function (entityName) {
    // KeywordRecognizer does not support entities
    return undefined;
  };
  KeywordRecognzierIntepretation.prototype.getProcessedText = function () {
    return this.processedText;
  };
  KeywordRecognzierIntepretation.prototype.getResponse = function () {
    return '';
  };
  KeywordRecognzierIntepretation.prototype.hasEntity = function (entityName) {
    // KeywordRecognizer does not support entities
    return false;
  };
  KeywordRecognzierIntepretation.prototype.isFallback = function () {
    return this.isFallbackFlag;
  };
  KeywordRecognzierIntepretation.prototype.wasSuccessful = function () {
    // Is always successfull (otherwise it's a bug in the code)
    return true;
  };
  // Each NLP system returns a different representation of the interpretation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  KeywordRecognzierIntepretation.prototype.getRawInterpretation = function () {
    return this.matchedIntentName;
  };
  KeywordRecognzierIntepretation.prototype.getLanguage = function () {
    return this.language;
  };
  KeywordRecognzierIntepretation.prototype.getInputSource = function () {
    return this.inputSource;
  };
  return KeywordRecognzierIntepretation;
}();
exports.KeywordRecognzierIntepretation = KeywordRecognzierIntepretation;
