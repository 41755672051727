import { __awaiter as i } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { validOriginsCdnEndpoint as t, validOriginsFallback as r } from "./constants.js";
import { GlobalVars as n } from "./globalVars.js";
import { getLogger as s } from "./telemetry.js";
import { inServerSideRenderingEnvironment as o, isValidHttpsURL as e } from "./utils.js";
let l = [];
const a = s("validateOrigin");
function u() {
  return i(this, void 0, void 0, function* () {
    yield d();
  });
}
function d() {
  return i(this, void 0, void 0, function* () {
    return 0 !== l.length ? l : o() ? (l = r, r) : fetch(t).then(i => {
      if (!i.ok) throw new Error("Invalid Response from Fetch Call");
      return i.json().then(i => {
        if (function (i) {
          let t = JSON.parse(i);
          try {
            t = JSON.parse(i);
          } catch (i) {
            return !1;
          }
          if (!t.validOrigins) return !1;
          for (const i of t.validOrigins) try {
            new URL("https://" + i);
          } catch (t) {
            return a("isValidOriginsFromCDN call failed to validate origin: %s", i), !1;
          }
          return !0;
        }(JSON.stringify(i))) return l = i.validOrigins, l;
        throw new Error("Valid Origins List Is Invalid");
      });
    }).catch(i => (a("validOrigins fetch call to CDN failed with error: %s. Defaulting to fallback list", i), l = r, l));
  });
}
function c(i, t) {
  if ("*." === i.substring(0, 2)) {
    const r = i.substring(1);
    if (t.length > r.length && t.split(".").length === r.split(".").length && t.substring(t.length - r.length) === r) return !0;
  } else if (i === t) return !0;
  return !1;
}
function f(i) {
  return d().then(t => {
    if (!e(i)) return a("Origin %s is invalid because it is not using https protocol. Protocol being used: %s", i, i.protocol), !1;
    const r = i.host;
    if (t.some(i => c(i, r))) return !0;
    for (const i of n.additionalValidOrigins) {
      if (c("https://" === i.substring(0, 8) ? i.substring(8) : i, r)) return !0;
    }
    return a("Origin %s is invalid because it is not an origin approved by this library or included in the call to app.initialize.\nOrigins approved by this library: %o\nOrigins included in app.initialize: %o", i, t, n.additionalValidOrigins), !1;
  });
}
export { u as prefetchOriginsFromCDN, f as validateOrigin };