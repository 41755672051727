import { __rest as i } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { UUID as t } from "./uuidObject.js";
const s = t => {
    const {
        uuid: s
      } = t,
      u = i(t, ["uuid"]),
      n = null == s ? void 0 : s.toString();
    return Object.assign(Object.assign({}, u), {
      uuidAsString: n
    });
  },
  u = s => {
    const {
        uuidAsString: u
      } = s,
      n = i(s, ["uuidAsString"]);
    return Object.assign(Object.assign({}, n), {
      uuid: u ? new t(u) : void 0
    });
  },
  n = s => {
    const {
        uuidAsString: u
      } = s,
      n = i(s, ["uuidAsString"]);
    return Object.assign(Object.assign({}, n), {
      uuid: u ? new t(u) : void 0
    });
  },
  r = t => {
    const {
        uuid: s
      } = t,
      u = i(t, ["uuid"]),
      n = null == s ? void 0 : s.toString();
    return Object.assign(Object.assign({}, u), {
      uuidAsString: n
    });
  };
export { u as deserializeMessageRequest, n as deserializeMessageResponse, s as serializeMessageRequest, r as serializeMessageResponse };