import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Translation} from 'botato-angular-lib/lib/translation';

@Injectable({
    providedIn: 'root'
})
export class TranslationLoaderService {

    private translations: Translation[] = [
        {
            language: 'de',
            translations: {
                'resourceViewer': {
                    'notAvailableError': {
                        'title': 'Ressource nicht verfügbar',
                        'description': 'Die angefragte Ressource ist leider nicht verfügbar.'
                    }
                },
                'badge': {
                    'locked': 'Unerreicht',
                    'level': 'Level',
                    'noLevel': 'Erreicht'
                },
                'learningGoal': {
                    'newbie': 'Neuling',
                    'beginners': 'Anfänger',
                    'advanced': 'Fortgeschrittener',
                    'skilled': 'Könner',
                    'professional': 'Profi',
                    'expert': 'Experte',
                    'noData': 'Keine freigeschalteten Lernpakete',
                    'noIntroduction': 'Keine Beschreibung vorhanden',
                    'feedback': 'Feedback geben',
                    'releaseDate': 'Letzte Aktualisierung:',
                    'ownerOrganization': 'Erstellt von',
                    'checkSticker': 'Lernziel erreicht',
                    'forgettingApplied': 'Zeit zum Lernen',
                    'navLearningProgress': 'Lernziel- und Fortschritt',
                    'navQuizzesOverview': 'Visualisierung des Lernfortschritts',
                    'navLearningPackageInfo': 'Informationen zum Lernpaket',
                    'learningGoal': 'Lernziel',
                    'numberOfQuestion': 'Fortschritt der {{numberOfQuestion}} Fragen',
                    'unansweredQuestions': 'davon {{neverAnsweredQuestions}} unbeantwortet',
                    'unansweredAndInactiveQuestions': 'davon {{neverAnsweredQuestions}} unbeantwortet und {{numberOfInactiveQuestions}} inaktiv',
                    'inactiveQuestions': 'davon {{numberOfInactiveQuestions}} inaktiv',
                    'learnButton': 'Dieses Lernpaket lernen',
                    'inactive': 'inaktiv'
                },
                'ranking': {
                    'allTime': 'allzeit',
                    'points': 'Pkt.',
                    'noOrganizationRanking': 'Für diesen Zeitraum sind keine Daten vorhanden.',
                    'noRankingData': 'Keine Rangliste verfügbar',
                    'currentMonth': 'aktueller Monat',
                    'lastMonth': 'letzter Monat',
                    'currentYear': 'aktuelles Jahr',
                    'lastYear': 'letztes Jahr'
                },
                'profile': {
                    'email': 'E-Mail',
                    'emailAndIdentifier': 'Benutzer / E‑Mail',
                    'identifier': 'Benutzer',
                    'name': 'Name',
                    'organizationName': 'Organisation',
                    'close': 'Schliessen',
                    'title': 'Profil',
                    'noOrganization': 'Keine Organisation',
                    'usePseudonym': 'Pseudonym anzeigen',
                    'activePseudonym': 'Ja',
                    'inactivePseudonym': 'Nein',
                    'pseudonym': 'Pseudonym',
                    'cancel': 'Verwerfen',
                    'save': 'Speichern',
                    'editButton': 'bearbeiten',
                    'monthlyReport': 'Monatlicher Fortschrittsbericht',
                    'monthlyReportSaved': 'Einstellungen gespeichert',
                    'locale':'Sprache:'
                },
                'moodle-widget-badge': {
                    'wrong-configuration':'Micromate ist nicht korrekt konfiguriert und kann darum nicht verwendet werden.',
                    'clickToStartSession':'Neue Lernsession starten...',
                    'title': 'Mit Micromate <strong class="micromate-link-title-package-name">{{learningPackageName}}</strong> lernen',
                    'micromateWillContactSoon': 'Micromate kontaktiert dich in Kürze...',
                    'couldNotStartMicromate': 'Lernpakete konnte nicht gestartet werden.',
                    'waitingForConnection': 'Warte auf Verbindung mit Micromate...'
                },
                'navigation': {
                    'chat': 'Chat',
                    'badges': 'Badges',
                    'studyProgress': 'Lernfortschritt',
                    'scoreboard': 'Rangliste',
                    'profile': 'Profil'
                },
                'general': {
                    'alreadyConnected': 'Micromate ist bereits in einem anderen Fenster geöffnet.',
                    'autherror': 'Bei der Anmeldung bei Micromate ist etwas schiefgelaufen. Bitte versuche es erneut. Sollte das Problem weiterhin bestehen, dann melde dich unter hello@micromate.ai.',
                    'autherrorButton': 'Anmeldung erneut versuchen',
                    'missingRole': 'Du verfügst nicht über die benötigten Berechtigungen. Melde dich bei deinem Micromate Verantwortlichen.',
                    'missingRoleButton': 'Abmelden',
                    'useMicromateHere': 'Micromate hier verwenden'
                },
                'shared': {
                    'locals': {
                        'af': 'Afrikaans',
                        'sq': 'Albanisch',
                        'eu': 'Baskisch',
                        'bs': 'Bosnisch',
                        'ca': 'Katalanisch',
                        'hr': 'Kroatisch',
                        'cs': 'Tschechisch',
                        'da': 'Dänisch',
                        'nl-nl': 'Niederländisch (Niederlande)',
                        'nl-be': 'Niederländisch (Belgien)',
                        'en-us': 'Englisch (USA)',
                        'en-gb': 'Englisch (Großbritannien)',
                        'en-ca': 'Englisch (Kanada)',
                        'en-au': 'Englisch (Australien)',
                        'en-in': 'Englisch (Indien)',
                        'en-sg': 'Englisch (Singapur)',
                        'en-nz': 'Englisch (Neuseeland)',
                        'en-za': 'Englisch (Südafrika)',
                        'en-ie': 'Englisch (Irland)',
                        'en-ph': 'Englisch (Philippinen)',
                        'et': 'Estnisch',
                        'fil': 'Filipino',
                        'fi': 'Finnisch',
                        'fr-fr': 'Französisch (Frankreich)',
                        'fr-ca': 'Französisch (Kanada)',
                        'fr-be': 'Französisch (Belgien)',
                        'fr-ch': 'Französisch (Schweiz)',
                        'fr-lu': 'Französisch (Luxemburg)',
                        'fr-mc': 'Französisch (Monaco)',
                        'gl': 'Galizisch',
                        'de-at': 'Deutsch (Österreich)',
                        'de-ch': 'Deutsch (Schweiz)',
                        'de-de': 'Deutsch (Deutschland)',
                        'de-lu': 'Deutsch (Luxemburg)',
                        'de-li': 'Deutsch (Liechtenstein)',
                        'hu': 'Ungarisch',
                        'is': 'Isländisch',
                        'id': 'Indonesisch',
                        'ga': 'Irisch',
                        'it': 'Italienisch',
                        'lv': 'Lettisch',
                        'lt': 'Litauisch',
                        'lb': 'Luxemburgisch',
                        'mt': 'Maltesisch',
                        'nb': 'Norwegisch Bokmål',
                        'pl': 'Polnisch',
                        'pt-br': 'Portugiesisch (Brasilien)',
                        'pt-pt': 'Portugiesisch (Portugal)',
                        'ro': 'Rumänisch',
                        'gd': 'Schottisch-Gälisch',
                        'sr': 'Serbisch',
                        'sk': 'Slowakisch',
                        'sl': 'Slowenisch',
                        'es-es': 'Spanisch (Spanien)',
                        'es-mx': 'Spanisch (Mexiko)',
                        'es-us': 'Spanisch (USA)',
                        'es-co': 'Spanisch (Kolumbien)',
                        'es-ar': 'Spanisch (Argentinien)',
                        'es-ve': 'Spanisch (Venezuela)',
                        'es-cl': 'Spanisch (Chile)',
                        'es-pe': 'Spanisch (Peru)',
                        'es-ec': 'Spanisch (Ecuador)',
                        'es-pr': 'Spanisch (Puerto Rico)',
                        'sv': 'Schwedisch',
                        'tr': 'Türkisch',
                        'vi': 'Vietnamesisch',
                        'cy': 'Walisisch'
                    }
                },
                'registration': {
                    'flows': {
                        'invitation': {
                            'title': 'Micromate Registrierung abschliessen',
                            'alreadyInitializedTitle': 'Ungültige Einladung',
                            'alreadyInitialized': 'Diese Einladung ist nicht mehr gültig. Kontaktiere die verantwortliche Person in deiner Organisation.<br><br>Falls du bereits registriert bist, kannst du dich mit deiner E-Mail und deinem Passwort unter <a href="https://learn.micromate.ai">learn.micromate.ai</a> anmelden.'
                        },
                        'selfregistrationwithorganisation': {
                            'title': 'Micromate Registrierung',
                            'invalidOrgCodeTitle': 'Ungültiger Registrierungslink',
                            'invalidOrgCode': 'Dieser Registrierungslink ist nicht gültig. Kontaktiere die verantwortliche Person in deiner Organisation.',
                            'joinByOrgCodeDisabledTitle': 'Ungültiger Registrierungslink',
                            'joinByOrgCodeDisabled': 'Dieser Registrierungslink ist nicht gültig. Kontaktiere die verantwortliche Person in deiner Organisation.',
                            'emailAlreadyTakenTitle': 'E-Mail bereits vergeben',
                            'emailAlreadyTaken': 'Die angegebene E-Mail-Adresse ist bereits vergeben.<br><br>Falls du bereits registriert bist, kannst du dich mit deiner E-Mail und deinem Passwort unter <a href="https://learn.micromate.ai">learn.micromate.ai</a> anmelden.',
                            'domainValidationFailedTitle': 'E-Mail ungültig',
                            'domainValidationFailed': 'Zur Registrierung ist nur eine E-Mail der Organisation {{organizationName}} zulässig.'
                        },
                        'selfregistrationwithoutorganisation': {
                            'title': 'Micromate Registrierung',
                            'emailAlreadyTakenTitle': 'E-Mail bereits vergeben',
                            'emailAlreadyTaken': 'Die angegebene E-Mail-Adresse ist bereits vergeben.<br><br>Falls du bereits registriert bist, kannst du dich mit deiner E-Mail und deinem Passwort unter <a href="https://learn.micromate.ai">learn.micromate.ai</a> anmelden.'
                        }
                    },
                    'organization': 'Organisation',
                    'email': 'E-Mail',
                    'firstName': 'Vorname',
                    'emailRequiredError': 'E-Mail muss angegeben werden',
                    'emailInvalidError': 'E-Mail ungültig',
                    'firstNameRequiredError': 'Vorname muss angegeben werden',
                    'lastName': 'Nachname',
                    'lastNameRequiredError': 'Nachname muss angegeben werden',
                    'language': 'Sprache',
                    'password': 'Passwort',
                    'passwordInvalidError': 'Ungültiges Passwort Format',
                    'passwordComplexityPolicyMinLength': 'Muss mindestens die Länge {{minLength}} haben',
                    'passwordComplexityPolicyHasNumber': 'Muss eine Zahle enthalten',
                    'passwordComplexityPolicyHasLowercase': 'Muss einen Kleinbuchstaben enthalten',
                    'passwordComplexityPolicyHasSymbol': 'Muss ein Symbol enthalten (z.B. $%*!?)',
                    'passwordComplexityPolicyHasUppercase': 'Muss einen Grossbuchstaben enthalten',
                    'confirmPassword': 'Passwort (Wiederholung)',
                    'confirmPasswordInvalidError': 'Passwort stimmt nicht überein',
                    'agbLinkTitle': 'Allgemeine Geschäftsbedingungen',
                    'dataPrivacyLinkTitle': 'Datenschutzbestimmungen',
                    'dataAccept': 'akzeptieren',
                    'registrationButton': 'Registrieren',
                    'registrationFailedErrorTitle': 'Registrierung fehlgeschlagen',
                    'registrationFailedError': 'Registration konnte nicht abgeschlossen werden. Bitte melde dich bei',

                    'invalidCodeError': 'Diese Einladung ist nicht mehr gültig. Falls du eine neue Einladung benötigst, melde dich bei deinem Micromate-Verantwortlichen.',
                    'exchangeCodeError': 'Registration aktuell nicht möglich. Bitte melde dich bei',
                    'parameterErrorTitle': 'Fehlerhafte Parameter',
                    'parameterError': 'Die angegebenen Parameter sind ungültig. Kontaktiere die verantwortliche Person in deiner Organisation.',
                    'confirmation': {
                        'title': 'Verifizierungsmail versendet',
                        'description': 'Wir haben dir eine E-Mail zur Bestätigung deiner E-Mailadresse an <strong>{{email}}</strong> gesendet. Klick auf den Link im E-Mail, um die Registrierung abzuschliessen. <br><br>Prüfe deinen Spamordner, falls du keine E-Mail bekommen hast.'
                    }
                },
                'feedback': {
                    'buttonTitle': 'Feedback geben',
                    'title': 'Verfasse dein Feedback',
                    'sendButton': 'Senden',
                    'infoBox': 'Zur Korrespondenz wird dein Name und deine E-Mail übermittelt',
                    'resultTitle': 'Danke für dein Feedback',
                    'resultSuccessMessage': 'Dein Feedback wurde der verantwortlichen Person übermittelt.',
                    'resultErrorMessage': 'Das Feedback konnte nicht eingereicht werden.',
                    'resultNotAllowFeedbackErrorMessage': 'Die Feedbackfunktion steht dir für diese Frage bzw. dieses Lernpaket nicht mehr zur Verfügung.',
                    'resultCloseButton': 'Schliessen',
                    'mainTopicTitle': 'Zu was willst du Feedback geben?',
                    'topicQuestionButton': 'Aktuelle Frage',
                    'topicLearningPackageButton': 'Aktuelles Lernpaket',
                    'topicTitle': 'Was willst du melden?',
                    'questionFaultyTopic': 'Die Aufgabe ist fehlerhaft',
                    'questionUnintelligibleTopic': 'Die Aufgabe ist unverständlich',
                    'questionSpellingTopic': 'Diese Aufgabe enthält Rechtschreibfehler',
                    'questionIdeaTopic': 'Ich habe eine Idee oder Anmerkung',
                    'learningPackageQuestionSuggestionTopic': 'Konkrete Frage vorschlagen',
                    'learningPackageTopicSuggestionTopic': 'Neues Inhaltsthema vorschlagen',
                    'learningPackageFeedbackTopic': 'Feedback zum Lernpaket geben',
                    'questionPreviewHide': 'Frage ausblenden',
                    'questionPreviewShow': 'Frage einblenden'
                },
                'popup': {
                    'wizardBackButton': 'Zurück'
                },
                'questionPreview': {
                    'questionTitle': 'Frage',
                    'answerTitle': 'Antworten',
                    'answerFalse': 'Falsch',
                    'answerTrue': 'Richtig',
                    'explanationTitle': 'Erläuterung',
                    'sourceTitle': 'Quelle'
                }
            }
        },
        {
            language: 'en',
            translations: {
                'resourceViewer': {
                    'notAvailableError': {
                        'title': 'Resource not available',
                        'description': 'Sorry, the requested resource is not available.'
                    }
                },
                'badge': {
                    'locked': 'Locked',
                    'level': 'Level',
                    'noLevel': 'Reached'
                },
                'learningGoal': {
                    'newbie': 'Newbie',
                    'beginners': 'Beginners',
                    'advanced': 'Advanced',
                    'skilled': 'Skilled',
                    'professional': 'Professional',
                    'expert': 'Expert',
                    'noData': 'No unlocked learning packages',
                    'noIntroduction': 'No description available',
                    'feedback': 'Give feedback',
                    'releaseDate': 'Last update:',
                    'ownerOrganization': 'Created by',
                    'checkSticker': 'Learning goal achieved',
                    'forgettingApplied': 'Time to learn',
                    'navLearningProgress': 'Learning goal and progress',
                    'navQuizzesOverview': 'Visualization of learning progress',
                    'navLearningPackageInfo': 'Learning package information',
                    'learningGoal': 'Learning goal',
                    'numberOfQuestion': 'Progress of {{numberOfQuestion}} questions',
                    'unansweredQuestions': 'of which {{neverAnsweredQuestions}} unanswered',
                    'unansweredAndInactiveQuestions': 'of which {{neverAnsweredQuestions}} unanswered and {{numberOfInactiveQuestions}} inactive',
                    'inactiveQuestions': 'of which {{numberOfInactiveQuestions}} inactive',
                    'learnButton': 'Learn this learning package',
                    'inactive': 'inactive'
                },
                'ranking': {
                    'allTime': 'all-time',
                    'points': 'pts',
                    'noOrganizationRanking': 'No data available for this period.',
                    'noRankingData': 'No ranking available',
                    'currentMonth': 'current month',
                    'lastMonth': 'last month',
                    'currentYear': 'current year',
                    'lastYear': 'last year'
                },
                'profile': {
                    'email': 'E-Mail',
                    'emailAndIdentifier': 'User / E‑Mail',
                    'identifier': 'User',
                    'name': 'Name',
                    'organizationName': 'Organisation',
                    'close': 'Close',
                    'title': 'Profile',
                    'noOrganization': 'No organization',
                    'usePseudonym': 'Show pseudonym',
                    'activePseudonym': 'Yes',
                    'inactivePseudonym': 'No',
                    'pseudonym': 'Pseudonym',
                    'cancel': 'Cancel',
                    'save': 'Save',
                    'editButton': 'edit',
                    'monthlyReport': 'Monthly progress report',
                    'monthlyReportSaved': 'Settings saved',
                    'locale':'Language:'
                },
                'moodle-widget-badge': {
                    'wrong-configuration':'Micromate is not configured correctly and therefore cannot be used.',
                    'clickToStartSession':'Start a new learning session...',
                    'title': 'Learn <strong class="micromate-link-title-package-name">{{learningPackageName}}</strong> with Micromate',
                    'micromateWillContactSoon': 'Micromate will contact you shortly...',
                    'couldNotStartMicromate': 'Learning packages could not be started.',
                    'waitingForConnection': 'Waiting for connection with Micromate...'
                },
                'navigation': {
                    'chat': 'Chat',
                    'badges': 'Badges',
                    'studyProgress': 'Study Progress',
                    'scoreboard': 'Scoreboard',
                    'profile': 'Profile'
                },
                'general': {
                    'alreadyConnected': 'Micromate is already open in another window. Close it and reload the page.',
                    'autherror': 'Something went wrong when registering with Micromate. Please try again. If the problem persists, please contact us at hello@micromate.ai.',
                    'autherrorButton': 'Try logging in again',
                    'missingRole': 'You do not have the required authorizations. Please contact your Micromate representative.',
                    'missingRoleButton': 'Log out',
                    'useMicromateHere': 'Use Micromate here'
                },
                'shared': {
                    'locals': {
                        'af': 'Afrikaans',
                        'sq': 'Albanian',
                        'eu': 'Basque',
                        'bs': 'Bosnian',
                        'ca': 'Catalan',
                        'hr': 'Croatian',
                        'cs': 'Czech',
                        'da': 'Danish',
                        'nl-nl': 'Dutch (Netherlands)',
                        'nl-be': 'Dutch (Belgium)',
                        'en-us': 'English (USA)',
                        'en-gb': 'English (UK)',
                        'en-ca': 'English (Canada)',
                        'en-au': 'English (Australia)',
                        'en-in': 'English (India)',
                        'en-sg': 'English (Singapore)',
                        'en-nz': 'English (New Zealand)',
                        'en-za': 'English (South Africa)',
                        'en-ie': 'English (Ireland)',
                        'en-ph': 'English (Philippines)',
                        'et': 'Estonian',
                        'fil': 'Filipino',
                        'fi': 'Finnish',
                        'fr-fr': 'French (France)',
                        'fr-ca': 'French (Canada)',
                        'fr-be': 'French (Belgium)',
                        'fr-ch': 'French (Switzerland)',
                        'fr-lu': 'French (Luxembourg)',
                        'fr-mc': 'French (Monaco)',
                        'gl': 'Galician',
                        'de-at': 'German (Austria)',
                        'de-ch': 'German (Switzerland)',
                        'de-de': 'German (Germany)',
                        'de-lu': 'German (Luxembourg)',
                        'de-li': 'German (Liechtenstein)',
                        'hu': 'Hungarian',
                        'is': 'Icelandic',
                        'id': 'Indonesian',
                        'ga': 'Irish',
                        'it': 'Italian',
                        'lv': 'Latvian',
                        'lt': 'Lithuanian',
                        'lb': 'Luxembourgish',
                        'mt': 'Maltese',
                        'nb': 'Norwegian Bokmål',
                        'pl': 'Polish',
                        'pt-br': 'Portuguese (Brazil)',
                        'pt-pt': 'Portuguese (Portugal)',
                        'ro': 'Romanian',
                        'gd': 'Scottish Gaelic',
                        'sr': 'Serbian',
                        'sk': 'Slovak',
                        'sl': 'Slovenian',
                        'es-es': 'Spanish (Spain)',
                        'es-mx': 'Spanish (Mexico)',
                        'es-us': 'Spanish (USA)',
                        'es-co': 'Spanish (Colombia)',
                        'es-ar': 'Spanish (Argentina)',
                        'es-ve': 'Spanish (Venezuela)',
                        'es-cl': 'Spanish (Chile)',
                        'es-pe': 'Spanish (Peru)',
                        'es-ec': 'Spanish (Ecuador)',
                        'es-pr': 'Spanish (Puerto Rico)',
                        'sv': 'Swedish',
                        'tr': 'Turkish',
                        'vi': 'Vietnamese',
                        'cy': 'Welsh'
                    }
                },
                'registration': {
                    'flows': {
                        'invitation': {
                            'title': 'Complete Micromate registration',
                            'alreadyInitializedTitle': 'Invalid invitation',
                            'alreadyInitialized': 'This invitation is no longer valid. Contact the person responsible in your organization.<br><br>If you are already registered, you can log in with your e-mail and password at <a href="https://learn.micromate.ai">learn.micromate.ai</a>.'
                        },
                        'selfregistrationwithorganisation': {
                            'title': 'Micromate registration',
                            'invalidOrgCodeTitle': 'Invalid registration link',
                            'invalidOrgCode': 'This registration link is not valid. Contact the responsible person in your organization.',
                            'joinByOrgCodeDisabledTitle': 'Invalid registration link',
                            'joinByOrgCodeDisabled': 'This registration link is not valid. Contact the responsible person in your organization.',
                            'emailAlreadyTakenTitle': 'E-mail already taken',
                            'emailAlreadyTaken': 'The e-mail address you entered is already taken.<br><br>If you are already registered, you can log in with your e-mail and password at <a href="https://learn.micromate.ai">learn.micromate.ai</a>.',
                            'domainValidationFailedTitle': 'E-mail invalid',
                            'domainValidationFailed': 'Only an e-mail from the organization {{organizationName}} is permitted for registration.'
                        },
                        'selfregistrationwithoutorganisation': {
                            'title': 'Micromate registration',
                            'emailAlreadyTakenTitle': 'E-mail already taken',
                            'emailAlreadyTaken': 'The e-mail address you entered is already taken.<br><br>If you are already registered, you can log in with your e-mail and password at <a href="https://learn.micromate.ai">learn.micromate.ai</a>.'
                        }
                    },
                    'organization': 'Organization',
                    'email': 'E-mail',
                    'emailRequiredError': 'E-mail must be specified',
                    'emailInvalidError': 'E-mail not valid',
                    'firstName': 'First name',
                    'firstNameRequiredError': 'First name must be specified',
                    'lastName': 'Last name',
                    'lastNameRequiredError': 'Last name must be specified',
                    'language': 'Language',
                    'password': 'Password',
                    'passwordInvalidError': 'Invalid password format',
                    'passwordComplexityPolicyMinLength': 'Must have a minimum length of {{minLength}}',
                    'passwordComplexityPolicyHasNumber': 'Must contain a number',
                    'passwordComplexityPolicyHasLowercase': 'Must contain a lowercase letter',
                    'passwordComplexityPolicyHasSymbol': 'Must contain a symbol (e.g. $%*!?)',
                    'passwordComplexityPolicyHasUppercase': 'Must contain an uppercase letter',
                    'confirmPassword': 'Password (repeat)',
                    'confirmPasswordInvalidError': 'Password does not match',
                    'agbLinkTitle': 'General terms and conditions',
                    'dataPrivacyLinkTitle': 'Privacy policy',
                    'dataAccept': 'accepted',
                    'registrationButton': 'Register',
                    'registrationFailedErrorTitle': 'Registration failed',
                    'registrationFailedError': 'Registration could not be completed. Please contact',
                    'invalidCodeError': 'This invitation is no longer valid. If you need a new invitation, please contact your Micromate representative.',
                    'exchangeCodeError': 'Registration currently not possible. Please contact',
                    'parameterErrorTitle': 'Incorrect parameters',
                    'parameterError': 'The specified parameters are invalid. Contact the person responsible in your organization.',
                    'confirmation': {
                        'title': 'Verification mail sent',
                        'description': 'We have sent you an email to <strong>{{email}}</strong> to confirm your email address. Click on the link in the email to finalise your registration. Check your spam folder if you have not received an email.'
                    }
                },
                'feedback': {
                    'buttonTitle': 'Give feedback',
                    'title': 'Write your feedback',
                    'sendButton': 'Send',
                    'infoBox': 'Your name and e-mail will be transmitted for correspondence',
                    'resultTitle': 'Thank you for your feedback',
                    'resultSuccessMessage': 'Your feedback has been sent to the person responsible.',
                    'resultErrorMessage': 'The feedback could not be submitted.',
                    'resultNotAllowFeedbackErrorMessage': 'The feedback function is no longer available for this question or learning package.',
                    'resultCloseButton': 'Close',
                    'mainTopicTitle': 'What would you like to give feedback on?',
                    'topicQuestionButton': 'Current question',
                    'topicLearningPackageButton': 'Current learning package',
                    'topicTitle': 'What do you want to report?',
                    'questionFaultyTopic': 'The task is incorrect',
                    'questionUnintelligibleTopic': 'The task is incomprehensible',
                    'questionSpellingTopic': 'This task contains spelling mistakes',
                    'questionIdeaTopic': 'I have an idea or comment',
                    'learningPackageQuestionSuggestionTopic': 'Suggest a specific question',
                    'learningPackageTopicSuggestionTopic': 'Suggest a new content topic',
                    'learningPackageFeedbackTopic': 'Giving feedback on the learning package',
                    'questionPreviewHide': 'Hide question',
                    'questionPreviewShow': 'Show question'
                },
                'popup': {
                    'wizardBackButton': 'Back'
                },
                'questionPreview': {
                    'questionTitle': 'Question',
                    'answerTitle': 'Answer',
                    'answerFalse': 'False',
                    'answerTrue': 'True',
                    'explanationTitle': 'Explanation',
                    'sourceTitle': 'Source'
                }
            }
        },
        {
            language: 'fr',
            translations: {
                'resourceViewer': {
                    'notAvailableError': {
                        'title': 'Ressource non disponible',
                        'description': 'Désolé, la ressource demandée n\'est pas disponible.'
                    }
                },
                'badge': {
                    'locked': 'Verroutllé',
                    'level': 'Niveau',
                    'noLevel': 'Atteint'
                },
                'learningGoal': {
                    'newbie': 'nouveau',
                    'beginners': 'débutant',
                    'advanced': 'avancé',
                    'skilled': 'expérimenté',
                    'professional': 'professionnel',
                    'expert': 'expert',
                    'noData': 'Aucun package d\'apprentissage déverrouillé',
                    'noIntroduction': 'Pas de description disponible',
                    'feedback': 'Donner un feedback',
                    'releaseDate': 'Dernière mise à jour:',
                    'ownerOrganization': 'Créé par',
                    'checkSticker': 'Objectif d\'apprentissage atteint',
                    'forgettingApplied': 'Temps d\'apprendre',
                    'navLearningProgress': 'Objectif d\'apprentissage et progression',
                    'navQuizzesOverview': 'Visualisation de la progression de formation',
                    'navLearningPackageInfo': 'Informations sur le kit de formation',
                    'learningGoal': 'Objectif d\'apprentissage',
                    'numberOfQuestion': 'Progression de {{numberOfQuestion}} questions',
                    'unansweredQuestions': 'dont {{neverAnsweredQuestions}} sans réponse',
                    'unansweredAndInactiveQuestions': 'dont {{neverAnsweredQuestions}} sans réponse et {{numberOfInactiveQuestions}} inactives',
                    'inactiveQuestions': 'dont {{numberOfInactiveQuestions}} inactives',
                    'learnButton': 'Apprenez ce module d\'apprentissage',
                    'inactive': 'inactif'
                },
                'ranking': {
                    'allTime': 'toujours',
                    'points': 'points',
                    'noOrganizationRanking': 'Aucune donnée n\'est disponible pour cette période.',
                    'noRankingData': 'Aucun classement disponible',
                    'currentMonth': 'mois actuel',
                    'lastMonth': 'mois dernier',
                    'currentYear': 'année actuelle',
                    'lastYear': 'année dernière'
                },
                'profile': {
                    'email': 'E-mail',
                    'emailAndIdentifier': 'Utilisateur / E‑mail',
                    'identifier': 'Utilisateur',
                    'name': 'Nom',
                    'organizationName': 'Organisation',
                    'close': 'Fermer',
                    'title': 'Profil',
                    'noOrganization': 'Aucune organisation',
                    'usePseudonym': 'Afficher le pseudonyme',
                    'activePseudonym': 'Oui',
                    'inactivePseudonym': 'Non',
                    'pseudonym': 'Pseudonyme',
                    'cancel': 'Rejeter',
                    'save': 'Enregistrer',
                    'editButton': 'éditer',
                    'monthlyReport': 'Rapport d\'avancement mensuel',
                    'monthlyReportSaved': 'Paramètres enregistrés',
                    'locale':'Langue:'
                },
                'moodle-widget-badge': {
                    'wrong-configuration':'Micromate n\'est pas configuré correctement et ne peut donc pas être utilisé.',
                    'clickToStartSession':'Démarrer une nouvelle session d\'apprentissage...',
                    'title': 'Apprendre  <strong class="micromate-link-title-package-name">{{learningPackageName}}</strong> avec Micromate',
                    'micromateWillContactSoon': 'Micromate te contactera sous peu...',
                    'couldNotStartMicromate': 'Les paquets d\'apprentissage n\'ont pas pu être démarrés.',
                    'waitingForConnection': 'En attente de connexion avec Micromate'
                },
                'navigation': {
                    'chat': 'Chat',
                    'badges': 'Badges',
                    'studyProgress': 'Progression de formation',
                    'scoreboard': 'Classement',
                    'profile': 'Profil'
                },
                'general': {
                    'alreadyConnected': 'Micromate est déjà ouvert dans une autre fenêtre. Ferme-la et recharge la page.',
                    'autherror': 'Quelque chose s\'est mal passé lors de l\'inscription à Micromate. Merci de réessayer. Si le problème persiste, contacte-nous à l\'adresse hello@micromate.ai.',
                    'autherrorButton': 'Réessayer de se connecter',
                    'missingRole': 'Tu ne disposes pas des autorisations nécessaires. Contacte ton responsable Micromate.',
                    'missingRoleButton': 'Se déconnecter',
                    'useMicromateHere': 'Utiliser Micromate ici'
                },
                'shared': {
                    'locals': {
                        'af': 'Afrikaans',
                        'sq': 'Albanais',
                        'eu': 'Basque',
                        'bs': 'Bosnien',
                        'ca': 'Catalan',
                        'hr': 'Croate',
                        'cs': 'Tchèque',
                        'da': 'Danois',
                        'nl-nl': 'Néerlandais (Pays-Bas)',
                        'nl-be': 'Néerlandais (Belgique)',
                        'en-us': 'Anglais (États-Unis)',
                        'en-gb': 'Anglais (Royaume-Uni)',
                        'en-ca': 'Anglais (Canada)',
                        'en-au': 'Anglais (Australie)',
                        'en-in': 'Anglais (Inde)',
                        'en-sg': 'Anglais (Singapour)',
                        'en-nz': 'Anglais (Nouvelle-Zélande)',
                        'en-za': 'Anglais (Afrique du Sud)',
                        'en-ie': 'Anglais (Irlande)',
                        'en-ph': 'Anglais (Philippines)',
                        'et': 'Estonien',
                        'fil': 'Filipino',
                        'fi': 'Finnois',
                        'fr-fr': 'Français (France)',
                        'fr-ca': 'Français (Canada)',
                        'fr-be': 'Français (Belgique)',
                        'fr-ch': 'Français (Suisse)',
                        'fr-lu': 'Français (Luxembourg)',
                        'fr-mc': 'Français (Monaco)',
                        'gl': 'Galicien',
                        'de-at': 'Allemand (Autriche)',
                        'de-ch': 'Allemand (Suisse)',
                        'de-de': 'Allemand (Allemagne)',
                        'de-lu': 'Allemand (Luxembourg)',
                        'de-li': 'Allemand (Liechtenstein)',
                        'hu': 'Hongrois',
                        'is': 'Islandais',
                        'id': 'Indonésien',
                        'ga': 'Irlandais',
                        'it': 'Italien',
                        'lv': 'Letton',
                        'lt': 'Lituanien',
                        'lb': 'Luxembourgeois',
                        'mt': 'Maltais',
                        'nb': 'Norvégien Bokmål',
                        'pl': 'Polonais',
                        'pt-br': 'Portugais (Brésil)',
                        'pt-pt': 'Portugais (Portugal)',
                        'ro': 'Roumain',
                        'gd': 'Gaélique écossais',
                        'sr': 'Serbe',
                        'sk': 'Slovaque',
                        'sl': 'Slovène',
                        'es-es': 'Espagnol (Espagne)',
                        'es-mx': 'Espagnol (Mexique)',
                        'es-us': 'Espagnol (États-Unis)',
                        'es-co': 'Espagnol (Colombie)',
                        'es-ar': 'Espagnol (Argentine)',
                        'es-ve': 'Espagnol (Venezuela)',
                        'es-cl': 'Espagnol (Chili)',
                        'es-pe': 'Espagnol (Pérou)',
                        'es-ec': 'Espagnol (Équateur)',
                        'es-pr': 'Espagnol (Porto Rico)',
                        'sv': 'Suédois',
                        'tr': 'Turc',
                        'vi': 'Vietnamien',
                        'cy': 'Gallois'
                    }
                },
                'registration': {
                    'flows': {
                        'invitation': {
                            'title': 'Compléter l\'inscription Micromate',
                            'alreadyInitializedTitle': 'Invitation non valide',
                            'alreadyInitialized': 'Cette invitation n\'est plus valable. Contacte la personne responsable dans ton organisation.<br><br>Si tu es déjà enregistré, tu peux te connecter avec ton e-mail et ton mot de passe sur <a href="https://learn.micromate.ai">learn.micromate.ai</a>.'
                        },
                        'selfregistrationwithorganisation': {
                            'title': 'Enregistrement Micromate',
                            'invalidOrgCodeTitle': 'Lien d\'enregistrement non valide',
                            'invalidOrgCode': 'Ce lien d\'enregistrement n\'est pas valide. Contacte la personne responsable dans ton organisation.',
                            'joinByOrgCodeDisabledTitle': 'Lien d\'enregistrement non valide',
                            'joinByOrgCodeDisabled': 'Ce lien d\'enregistrement n\'est pas valide. Contacte la personne responsable dans ton organisation.',
                            'emailAlreadyTakenTitle': 'E-mail déjà attribué',
                            'emailAlreadyTaken': 'L\'adresse e-mail indiquée est déjà utilisée.<br><br>Si tu es déjà enregistré, tu peux te connecter avec ton e-mail et ton mot de passe sur <a href="https://learn.micromate.ai">learn.micromate.ai</a>.',
                            'domainValidationFailedTitle': 'E-mail non valide',
                            'domainValidationFailed': 'Pour l\'enregistrement, seul un e-mail de l\'organisation {{organizationName}} est autorisé.'
                        },
                        'selfregistrationwithoutorganisation': {
                            'title': 'Enregistrement Micromate',
                            'emailAlreadyTakenTitle': 'E-mail déjà attribué',
                            'emailAlreadyTaken': 'L\'adresse e-mail indiquée est déjà utilisée.<br><br>Si tu es déjà enregistré, tu peux te connecter avec ton e-mail et ton mot de passe sur <a href="https://learn.micromate.ai">learn.micromate.ai</a>.'
                        }
                    },
                    'organization': 'Organisation',
                    'email': 'E-mail',
                    'emailRequiredError': 'L\'e-mail doit être spécifié',
                    'emailInvalidError': 'E-mail non valide',
                    'firstName': 'Prénom',
                    'firstNameRequiredError': 'Le prénom doit être spécifié',
                    'lastName': 'Nom de famille',
                    'lastNameRequiredError': 'Le nom de famille doit être spécifié',
                    'language': 'Langue',
                    'password': 'Mot de passe',
                    'passwordInvalidError': 'Format de mot de passe non valide',
                    'passwordComplexityPolicyMinLength': 'Doit avoir une longueur minimale de {{minLength}}',
                    'passwordComplexityPolicyHasNumber': 'Doit contenir un nombre',
                    'passwordComplexityPolicyHasLowercase': 'Doit contenir une lettre minuscule',
                    'passwordComplexityPolicyHasSymbol': 'Doit contenir un symbole (par exemple, $%*!?)',
                    'passwordComplexityPolicyHasUppercase': 'Doit contenir une lettre majuscule',
                    'confirmPassword': 'Mot de passe (répéter)',
                    'confirmPasswordInvalidError': 'Le mot de passe ne correspond pas',
                    'agbLinkTitle': 'Conditions générales',
                    'dataPrivacyLinkTitle': 'Politique de confidentialité',
                    'dataAccept': 'acceptent',
                    'registrationButton': 'Register',
                    'registrationFailedErrorTitle': 'Échec de l\'enregistrement',
                    'registrationFailedError': 'L\'enregistrement n\'a pas pu être effectué. Veuillez contacter',
                    'invalidCodeError': 'Cette invitation n\'est plus valable. Si vous avez besoin d\'une nouvelle invitation, veuillez contacter votre représentant Micromate.',
                    'exchangeCodeError': 'L\'inscription est actuellement impossible. Veuillez contacter',
                    'parameterErrorTitle': 'Paramètres erronés',
                    'parameterError': 'Les paramètres indiqués ne sont pas valables. Contacte la personne responsable dans ton organisation.',
                    'confirmation': {
                        'title': 'Envoi d\'un e-mail de vérification',
                        'description': 'Nous t\'avons envoyé un e-mail de confirmation de ton adresse e-mail à <strong>{{email}}</strong>. Clique sur le lien dans l\'e-mail pour terminer l\'enregistrement. Vérifie ton dossier spam si tu n\'as pas reçu d\'e-mail.'
                    }
                },
                'feedback': {
                    'buttonTitle': 'Donner un feedback',
                    'title': 'Rédige ton commentaire',
                    'sendButton': 'Envoyer',
                    'infoBox': 'Ton nom et ton e-mail sont transmis pour la correspondance.',
                    'resultTitle': 'Merci pour tes commentaires',
                    'resultSuccessMessage': 'Ton commentaire a été transmis à la personne responsable.',
                    'resultErrorMessage': 'Le feedback n\'a pas pu être soumis.',
                    'resultNotAllowFeedbackErrorMessage': 'La fonction de feedback n\'est plus disponible pour cette question ou ce dossier pédagogique.',
                    'resultCloseButton': 'Fermer',
                    'mainTopicTitle': 'Sur quoi veux-tu donner un feedback ?',
                    'topicQuestionButton': 'Question d\'actualité',
                    'topicLearningPackageButton': 'Dossier pédagogique actuel',
                    'topicTitle': 'Que veux-tu signaler ?',
                    'questionFaultyTopic': 'La tâche est incorrecte',
                    'questionUnintelligibleTopic': 'La tâche est incompréhensible',
                    'questionSpellingTopic': 'Cette tâche contient des fautes d\'orthographe',
                    'questionIdeaTopic': 'J\'ai une idée ou une remarque',
                    'learningPackageQuestionSuggestionTopic': 'Proposer une question concrète',
                    'learningPackageTopicSuggestionTopic': 'Proposer un nouveau thème de contenu',
                    'learningPackageFeedbackTopic': 'Donner un feedback sur le kit d\'apprentissage',
                    'questionPreviewHide': 'Masquer la question',
                    'questionPreviewShow': 'Afficher la question'
                },
                'popup': {
                    'wizardBackButton': 'Retour'
                },
                'questionPreview': {
                    'questionTitle': 'Demander',
                    'answerTitle': 'Répondre',
                    'answerFalse': 'Faux',
                    'answerTrue': 'Vrai',
                    'explanationTitle': 'Explication',
                    'sourceTitle': 'Source'
                }
            }

        },
        {
            language: 'it',
            translations: {
                'CHARACTER_INDICATOR': '{{length}} / {{maxLength}} segni',
                'NO_CONNECTION_ERROR': 'Problema di connessione: tentativo di connessione al server...',
                'ITEM_LIST_CHOOSE': 'Selezionare',
                'PLACEHOLDER': 'Inserisci messaggio...',
                'FILE_UPLOAD': {
                    'TITLE_ERROR_MULTIPLE': 'Impossibile caricare i file:',
                    'TITLE_ERROR_SINGLE': 'Impossibile caricare i file:',
                    'FILE_ERROR_SIZE': 'File troppo grande',
                    'FILE_ERROR_TYPE': 'Formato file errato',
                    'FILE_ERROR_UNKNOWN': 'Errore sconosciuto'
                },
                'AUTOCOMPLETE': {
                    'HINT_NO_ENTRIES': 'Nessun risultato trovato'
                },
                'resourceViewer': {
                    'notAvailableError': {
                        'title': 'Risorsa non disponibile',
                        'description': 'Spiacente, la risorsa richiesta non è disponibile.'
                    }
                },
                'badge': {
                    'locked': 'Bloccato',
                    'level': 'Livello',
                    'noLevel': 'Raggiunto'
                },
                'learningGoal': {
                    'newbie': 'Principiante',
                    'beginners': 'Inesperto',
                    'advanced': 'Avanzato',
                    'skilled': 'Competente',
                    'professional': 'Professionista',
                    'expert': 'Esperto',
                    'noData': 'Nessun pacchetto di apprendimento sbloccato',
                    'noIntroduction': 'Nessuna descrizione disponibile',
                    'feedback': 'Dare un feedback',
                    'releaseDate': 'Ultimo aggiornamento:',
                    'ownerOrganization': 'Creato da',
                    'checkSticker': 'Obiettivo di apprendimento raggiunto',
                    'forgettingApplied': 'Tempo per imparare',
                    'navLearningProgress': 'Obiettivo di apprendimento e progresso',
                    'navQuizzesOverview': 'Visualizzazione del progresso formativo',
                    'navLearningPackageInfo': 'Informazioni sul pacchetto formativo',
                    'learningGoal': 'Obiettivo di apprendimento',
                    'numberOfQuestion': 'Avanzamento di {{numberOfQuestion}} domande',
                    'unansweredQuestions': 'di cui {{neverAnsweredQuestions}} senza risposta',
                    'unansweredAndInactiveQuestions': 'di cui {{neverAnsweredQuestions}} senza risposta e {{numberOfInactiveQuestions}} inattive',
                    'inactiveQuestions': 'di cui {{numberOfInactiveQuestions}} inattive',
                    'learnButton': 'Scopri questo pacchetto di apprendimento',
                    'inactive': 'inattivo'
                },
                'ranking': {
                    'allTime': 'sempre',
                    'points': 'punti',
                    'noOrganizationRanking': 'Non sono disponibili dati per questo periodo.',
                    'noRankingData': 'Nessuna classifica disponibile',
                    'currentMonth': 'mese corrente',
                    'lastMonth': 'mese scorso',
                    'currentYear': 'anno corrente',
                    'lastYear': 'anno scorso'
                },
                'profile': {
                    'email': 'E-mail',
                    'emailAndIdentifier': 'Utente / E-mail',
                    'identifier': 'Utente',
                    'name': 'Nome',
                    'organizationName': 'Organizzazione',
                    'close': 'Vicino',
                    'title': 'Profilo',
                    'noOrganization': 'Nessuna organizzazione',
                    'usePseudonym': 'Mostra pseudonimo',
                    'activePseudonym': 'Si',
                    'inactivePseudonym': 'No',
                    'pseudonym': 'Pseudonimo',
                    'cancel': 'Scartare',
                    'save': 'Salva',
                    'editButton': 'modifica',
                    'monthlyReport': 'Relazione mensile sui progressi compiuti',
                    'monthlyReportSaved': 'Impostazioni salvate',
                    'locale':'Lingua:'
                },
                'moodle-widget-badge': {
                    'wrong-configuration':'Micromate non è configurato correttamente e quindi non può essere utilizzato.',
                    'clickToStartSession':'Avviare una nuova sessione di apprendimento...',
                    'title': 'Imparare  <strong class="micromate-link-title-package-name">{{learningPackageName}}</strong> con Micromate',
                    'micromateWillContactSoon': 'Micromate vi contatterà a breve...',
                    'couldNotStartMicromate': 'Non è stato possibile avviare i pacchetti didattici.',
                    'waitingForConnection': 'In attesa del collegamento con Micromate...'
                },
                'navigation': {
                    'chat': 'Chat',
                    'badges': 'Riconoscimenti',
                    'studyProgress': 'Progresso formativo',
                    'scoreboard': 'Classifica',
                    'profile': 'Profilo'
                },
                'general': {
                    'alreadyConnected': 'Micromate è già aperto in un\'altra finestra. Chiuderla e ricaricare la pagina.',
                    'autherror': 'Qualcosa è andato storto durante la registrazione con Micromate. Si prega di riprovare. Se il problema persiste, contattateci all\'indirizzo hello@micromate.ai.',
                    'autherrorButton': 'Provare ad accedere di nuovo',
                    'missingRole': 'Non si dispone delle autorizzazioni necessarie. Contattate il vostro rappresentante Micromate.',
                    'missingRoleButton': 'Disconnettersi',
                    'useMicromateHere': 'Utilizzate Micromate qui'
                },
                'shared': {
                    'locals': {
                        'af': 'Afrikaans',
                        'sq': 'Albanese',
                        'eu': 'Basco',
                        'bs': 'Bosniaco',
                        'ca': 'Catalano',
                        'hr': 'Croato',
                        'cs': 'Ceco',
                        'da': 'Danese',
                        'nl-nl': 'Olandese (Paesi Bassi)',
                        'nl-be': 'Olandese (Belgio)',
                        'en-us': 'Inglese (USA)',
                        'en-gb': 'Inglese (Regno Unito)',
                        'en-ca': 'Inglese (Canada)',
                        'en-au': 'Inglese (Australia)',
                        'en-in': 'Inglese (India)',
                        'en-sg': 'Inglese (Singapore)',
                        'en-nz': 'Inglese (Nuova Zelanda)',
                        'en-za': 'Inglese (Sudafrica)',
                        'en-ie': 'Inglese (Irlanda)',
                        'en-ph': 'Inglese (Filippine)',
                        'et': 'Estone',
                        'fil': 'Filippino',
                        'fi': 'Finlandese',
                        'fr-fr': 'Francese (Francia)',
                        'fr-ca': 'Francese (Canada)',
                        'fr-be': 'Francese (Belgio)',
                        'fr-ch': 'Francese (Svizzera)',
                        'fr-lu': 'Francese (Lussemburgo)',
                        'fr-mc': 'Francese (Monaco)',
                        'gl': 'Galiziano',
                        'de-at': 'Tedesco (Austria)',
                        'de-ch': 'Tedesco (Svizzera)',
                        'de-de': 'Tedesco (Germania)',
                        'de-lu': 'Tedesco (Lussemburgo)',
                        'de-li': 'Tedesco (Liechtenstein)',
                        'hu': 'Ungherese',
                        'is': 'Islandese',
                        'id': 'Indonesiano',
                        'ga': 'Irlandese',
                        'it': 'Italiano',
                        'lv': 'Lettone',
                        'lt': 'Lituano',
                        'lb': 'Lussemburghese',
                        'mt': 'Maltese',
                        'nb': 'Norvegese Bokmål',
                        'pl': 'Polacco',
                        'pt-br': 'Portoghese (Brasile)',
                        'pt-pt': 'Portoghese (Portogallo)',
                        'ro': 'Rumeno',
                        'gd': 'Gaelico Scozzese',
                        'sr': 'Serbo',
                        'sk': 'Slovacco',
                        'sl': 'Sloveno',
                        'es-es': 'Spagnolo (Spagna)',
                        'es-mx': 'Spagnolo (Messico)',
                        'es-us': 'Spagnolo (USA)',
                        'es-co': 'Spagnolo (Colombia)',
                        'es-ar': 'Spagnolo (Argentina)',
                        'es-ve': 'Spagnolo (Venezuela)',
                        'es-cl': 'Spagnolo (Cile)',
                        'es-pe': 'Spagnolo (Perù)',
                        'es-ec': 'Spagnolo (Ecuador)',
                        'es-pr': 'Spagnolo (Porto Rico)',
                        'sv': 'Svedese',
                        'tr': 'Turco',
                        'vi': 'Vietnamita',
                        'cy': 'Gallese'
                    }
                },
                'registration': {
                    'flows': {
                        'invitation': {
                            'title': 'Completare la registrazione di Micromate',
                            'alreadyInitializedTitle': 'Invito non valido',
                            'alreadyInitialized': 'Questo invito non è più valido. Contattate la persona responsabile della vostra organizzazione.<br><br>Se siete già registrati, potete accedere con il vostro indirizzo e-mail e la vostra password all\'indirizzo <a href="https://learn.micromate.ai">learn.micromate.ai</a>.'
                        },
                        'selfregistrationwithorganisation': {
                            'title': 'Registrazione Micromate',
                            'invalidOrgCodeTitle': 'Link di registrazione non valido',
                            'invalidOrgCode': 'Questo link di registrazione non è valido. Contattare la persona responsabile della propria organizzazione.',
                            'joinByOrgCodeDisabledTitle': 'Link di registrazione non valido',
                            'joinByOrgCodeDisabled': 'Questo link di registrazione non è valido. Contattare la persona responsabile della propria organizzazione.',
                            'emailAlreadyTakenTitle': 'E-mail già presa',
                            'emailAlreadyTaken': 'L\'indirizzo e-mail inserito è già occupato.<br><br>Se siete già registrati, potete accedere con il vostro indirizzo e-mail e la vostra password all\'indirizzo <a href="https://learn.micromate.ai">learn.micromate.ai</a>.',
                            'domainValidationFailedTitle': 'E-mail non valida',
                            'domainValidationFailed': 'Per la registrazione è consentita solo un\'e-mail dell\'organizzazione {{organizationName}}.'
                        },
                        'selfregistrationwithoutorganisation': {
                            'title': 'Registrazione Micromate',
                            'emailAlreadyTakenTitle': 'E-mail già presa',
                            'emailAlreadyTaken': 'L\'indirizzo e-mail inserito è già occupato.<br><br>Se siete già registrati, potete accedere con il vostro indirizzo e-mail e la vostra password all\'indirizzo <a href="https://learn.micromate.ai">learn.micromate.ai</a>.'
                        }
                    },
                    'organization': 'Organizzazione',
                    'email': 'E-mail',
                    'emailRequiredError': 'L\'e-mail deve essere specificato',
                    'emailInvalidError': 'E-mail non valida',
                    'firstName': 'Nome',
                    'firstNameRequiredError': 'Il nome deve essere specificato',
                    'lastName': 'Cognome',
                    'lastNameRequiredError': 'Il cognome deve essere specificato',
                    'language': 'Lingua',
                    'password': 'Password',
                    'passwordInvalidError': 'Formato password non valido',
                    'passwordComplexityPolicyMinLength': 'Deve avere una lunghezza minima di {{minLength}}',
                    'passwordComplexityPolicyHasNumber': 'Deve contenere un numero',
                    'passwordComplexityPolicyHasLowercase': 'Deve contenere una lettera minuscola',
                    'passwordComplexityPolicyHasSymbol': 'Deve contenere un simbolo (ad esempio $%*!?)',
                    'passwordComplexityPolicyHasUppercase': 'Deve contenere una lettera maiuscola',
                    'confirmPassword': 'Password (ripetuta)',
                    'confirmPasswordInvalidError': 'La password non corrisponde',
                    'agbLinkTitle': 'Termini e condizioni generali',
                    'dataPrivacyLinkTitle': 'Informativa sulla privacy',
                    'dataAccept': 'accettare',
                    'registrationButton': 'Registrati',
                    'invalidCodeError': 'Questo invito non è più valido. Se avete bisogno di un nuovo invito, contattate il vostro rappresentante Micromate.',
                    'exchangeCodeError': 'La registrazione non è attualmente possibile. Si prega di contattare',
                    'registrationFailedErrorTitle': 'Registrazione fallita',
                    'registrationFailedError': 'Non è stato possibile completare la registrazione. Si prega di contattare',
                    'parameterErrorTitle': 'Parametri errati',
                    'parameterError': 'I parametri specificati non sono validi. Contattare il responsabile della propria organizzazione.',
                    'confirmation': {
                        'title': 'Mail di verifica inviata',
                        'description': 'Le abbiamo inviato un\'e-mail all\'indirizzo <strong>{{email}}</strong> per confermare il suo indirizzo e-mail. Fare clic sul link contenuto nell\'e-mail per completare la registrazione. Controllare la cartella spam se non si è ricevuta l\'e-mail.'
                    }
                },
                'feedback': {
                    'buttonTitle': 'Dare un feedback',
                    'title': 'Scrivete il vostro feedback',
                    'sendButton': 'Inviare',
                    'infoBox': 'Il vostro nome e l\'e-mail saranno trasmessi per la corrispondenza.',
                    'resultTitle': 'Grazie per il vostro feedback',
                    'resultSuccessMessage': 'Il vostro feedback è stato inviato alla persona responsabile.',
                    'resultErrorMessage': 'Non è stato possibile inviare il feedback.',
                    'resultNotAllowFeedbackErrorMessage': 'La funzione di feedback non è più disponibile per questa domanda o per questo pacchetto didattico.',
                    'resultCloseButton': 'Chiudere',
                    'mainTopicTitle': 'Su cosa volete dare un feedback?',
                    'topicQuestionButton': 'Domanda attuale',
                    'topicLearningPackageButton': 'Pacchetto didattico attuale',
                    'topicTitle': 'Che cosa vuole riferire?',
                    'questionFaultyTopic': 'Il compito non è corretto',
                    'questionUnintelligibleTopic': 'Il compito è incomprensibile',
                    'questionSpellingTopic': 'Questo compito contiene errori di ortografia',
                    'questionIdeaTopic': 'Ho un\'idea o un commento',
                    'learningPackageQuestionSuggestionTopic': 'Suggerite una domanda specifica',
                    'learningPackageTopicSuggestionTopic': 'Suggerisci un nuovo argomento di contenuto',
                    'learningPackageFeedbackTopic': 'Feedback sul pacchetto didattico',
                    'questionPreviewHide': 'Nascondi la domanda',
                    'questionPreviewShow': 'Mostra domanda'
                },
                'popup': {
                    'wizardBackButton': 'Indietro'
                },
                'questionPreview': {
                    'questionTitle': 'Domanda',
                    'answerTitle': 'Risposte',
                    'answerFalse': 'Falso',
                    'answerTrue': 'Vero',
                    'explanationTitle': 'Spiegazione',
                    'sourceTitle': 'Spiegazione'
                }
            }
        },
        {
            language: 'nl',
            translations: {
                'CHARACTER_INDICATOR': '{{length}} / {{maxLength}} Karakters',
                'NO_CONNECTION_ERROR': 'Verbindingsprobleem: probeert verbinding te maken met de server...',
                'ITEM_LIST_CHOOSE': 'Selecteer',
                'PLACEHOLDER': 'Bericht invoeren...',
                'FILE_UPLOAD': {
                    'TITLE_ERROR_MULTIPLE': 'Bestanden konden niet worden geladen:',
                    'TITLE_ERROR_SINGLE': 'Bestand kan niet worden geladen:',
                    'FILE_ERROR_SIZE': 'Bestand te groot',
                    'FILE_ERROR_TYPE': 'Verkeerd bestandsformaat',
                    'FILE_ERROR_UNKNOWN': 'Onbekende fout'
                },
                'AUTOCOMPLETE': {
                    'HINT_NO_ENTRIES': 'Geen zoekresultaten'
                },
                'resourceViewer': {
                    'notAvailableError': {
                        'title': 'Bron niet beschikbaar',
                        'description': 'Sorry, de gevraagde bron is niet beschikbaar.'
                    }
                },
                'badge': {
                    'locked': 'Vergrendeld',
                    'level': 'Level',
                    'noLevel': 'Bereikt'
                },
                'learningGoal': {
                    'newbie': 'Nieuwkomer',
                    'beginners': 'Beginners',
                    'advanced': 'Deskundige',
                    'skilled': 'Gevorderde',
                    'professional': 'Professional',
                    'expert': 'Expert',
                    'noData': 'Geen ontgrendelde lespakketten',
                    'noIntroduction': 'Geen beschrijving beschikbaar',
                    'feedback': 'Feedback geven',
                    'releaseDate': 'Laatst bijgewerkt:',
                    'ownerOrganization': 'Gemaakt door',
                    'checkSticker': 'Leerdoel bereikt',
                    'forgettingApplied': 'Tijd om te leren',
                    'navLearningProgress': 'Leerdoel en voortgang',
                    'navQuizzesOverview': 'Overzicht van de leervoortgang',
                    'navLearningPackageInfo': 'Informatie over het lespakket',
                    'learningGoal': 'Leerdoel',
                    'numberOfQuestion': 'Voortgang van de {{numberOfQuestion}} vragen',
                    'unansweredQuestions': 'waarvan {{neverAnsweredQuestions}} onbeantwoord',
                    'unansweredAndInactiveQuestions': 'waarvan {{neverAnsweredQuestions}} onbeantwoord en {{numberOfInactiveQuestions}} inactief',
                    'inactiveQuestions': 'waarvan {{numberOfInactiveQuestions}} inactief',
                    'learnButton': 'Leer dit lespakket',
                    'inactive': 'inactief'
                },
                'ranking': {
                    'allTime': 'allTime',
                    'points': 'pt',
                    'noOrganizationRanking': 'Voor deze periode zijn geen gegevens beschikbaar.',
                    'noRankingData': 'Geen ranglijst beschikbaar',
                    'currentMonth': 'deze maand',
                    'lastMonth': 'vorige maand',
                    'currentYear': 'dit jaar',
                    'lastYear': 'vorig jaar'
                },
                'profile': {
                    'email': 'E-mail',
                    'emailAndIdentifier': 'Gebruiker / E-mail',
                    'identifier': 'Gebruiker',
                    'name': 'Naam',
                    'organizationName': 'Organisatie',
                    'close': 'Dichtbij',
                    'title': 'Profiel',
                    'noOrganization': 'Geen organisatie',
                    'usePseudonym': 'Pseudoniem tonen',
                    'activePseudonym': 'Ja',
                    'inactivePseudonym': 'Nee',
                    'pseudonym': 'Pseudoniem',
                    'cancel': 'Gooi weg',
                    'save': 'Opslaan',
                    'editButton': 'bewerken',
                    'monthlyReport': 'Maandelijks voortgangsverslag',
                    'monthlyReportSaved': 'Instellingen opgeslagen',
                    'locale':'Taal:'
                },
                'moodle-widget-badge': {
                    'wrong-configuration':'Micromate is niet correct geconfigureerd en kan daarom niet worden gebruikt.',
                    'clickToStartSession':'Een nieuwe leersessie starten...',
                    'title': 'Leren <strong class="micromate-link-title-package-name">{{learningPackageName}}</strong> met Micromate',
                    'micromateWillContactSoon': 'Micromate neemt binnenkort contact met u op...',
                    'couldNotStartMicromate': 'Leerpakketten konden niet worden gestart.',
                    'waitingForConnection': 'Wachten op verbinding met Micromate...'
                },
                'navigation': {
                    'chat': 'Chat',
                    'badges': 'Badges',
                    'studyProgress': 'Leervoortgang',
                    'scoreboard': 'Ranglijst',
                    'profile': 'Profiel'
                },
                'general': {
                    'alreadyConnected': 'Micromate is al geopend in een ander venster.',
                    'autherror': 'Er is iets misgegaan bij het registreren bij Micromate. Probeer het opnieuw. Als het probleem zich blijft voordoen, neem dan contact met ons op via hello@micromate.ai.',
                    'autherrorButton': 'Probeer opnieuw in te loggen',
                    'missingRole': 'U beschikt niet over de vereiste autorisaties. Neem contact op met uw Micromate-vertegenwoordiger.',
                    'missingRoleButton': 'Uitloggen',
                    'useMicromateHere': 'Gebruik Micromate hier'
                },
                'shared': {
                    'locals': {
                        'af': 'Afrikaans',
                        'sq': 'Albanees',
                        'eu': 'Baskisch',
                        'bs': 'Bosnisch',
                        'ca': 'Catalaans',
                        'hr': 'Kroatisch',
                        'cs': 'Tsjechisch',
                        'da': 'Deens',
                        'nl-nl': 'Nederlands (Nederland)',
                        'nl-be': 'Nederlands (België)',
                        'en-us': 'Engels (VS)',
                        'en-gb': 'Engels (Verenigd Koninkrijk)',
                        'en-ca': 'Engels (Canada)',
                        'en-au': 'Engels (Australië)',
                        'en-in': 'Engels (India)',
                        'en-sg': 'Engels (Singapore)',
                        'en-nz': 'Engels (Nieuw-Zeeland)',
                        'en-za': 'Engels (Zuid-Afrika)',
                        'en-ie': 'Engels (Ierland)',
                        'en-ph': 'Engels (Filipijnen)',
                        'et': 'Estisch',
                        'fil': 'Filipijns',
                        'fi': 'Fins',
                        'fr-fr': 'Frans (Frankrijk)',
                        'fr-ca': 'Frans (Canada)',
                        'fr-be': 'Frans (België)',
                        'fr-ch': 'Frans (Zwitserland)',
                        'fr-lu': 'Frans (Luxemburg)',
                        'fr-mc': 'Frans (Monaco)',
                        'gl': 'Galicisch',
                        'de-at': 'Duits (Oostenrijk)',
                        'de-ch': 'Duits (Zwitserland)',
                        'de-de': 'Duits (Duitsland)',
                        'de-lu': 'Duits (Luxemburg)',
                        'de-li': 'Duits (Liechtenstein)',
                        'hu': 'Hongaars',
                        'is': 'IJslands',
                        'id': 'Indonesisch',
                        'ga': 'Iers',
                        'it': 'Italiaans',
                        'lv': 'Lets',
                        'lt': 'Litouws',
                        'lb': 'Luxemburgs',
                        'mt': 'Maltees',
                        'nb': 'Noors (Bokmål)',
                        'pl': 'Pools',
                        'pt-br': 'Portugees (Brazilië)',
                        'pt-pt': 'Portugees (Portugal)',
                        'ro': 'Roemeens',
                        'gd': 'Schots-Gaelisch',
                        'sr': 'Servisch',
                        'sk': 'Slowaaks',
                        'sl': 'Sloveens',
                        'es-es': 'Spaans (Spanje)',
                        'es-mx': 'Spaans (Mexico)',
                        'es-us': 'Spaans (VS)',
                        'es-co': 'Spaans (Colombia)',
                        'es-ar': 'Spaans (Argentinië)',
                        'es-ve': 'Spaans (Venezuela)',
                        'es-cl': 'Spaans (Chili)',
                        'es-pe': 'Spaans (Peru)',
                        'es-ec': 'Spaans (Ecuador)',
                        'es-pr': 'Spaans (Puerto Rico)',
                        'sv': 'Zweeds',
                        'tr': 'Turks',
                        'vi': 'Vietnamees',
                        'cy': 'Welsh'
                    }
                },
                'registration': {
                    'flows': {
                        'invitation': {
                            'title': 'Registratie van Micromate voltooien',
                            'alreadyInitializedTitle': 'Ongeldige uitnodiging',
                            'alreadyInitialized': 'Deze uitnodiging is niet langer geldig. Neem contact op met de verantwoordelijke in uw organisatie.<br><br>Als u al geregistreerd bent, kunt u inloggen met uw e-mailadres en wachtwoord op <a href="https://learn.micromate.ai">learn.micromate.ai</a>.'
                        },
                        'selfregistrationwithorganisation': {
                            'title': 'Micromaat registratie',
                            'invalidOrgCodeTitle': 'Ongeldige registratielink',
                            'invalidOrgCode': 'Deze registratielink is niet geldig. Neem contact op met de verantwoordelijke persoon in uw organisatie.',
                            'joinByOrgCodeDisabledTitle': 'Ongeldige registratielink',
                            'joinByOrgCodeDisabled': 'Deze registratielink is niet geldig. Neem contact op met de verantwoordelijke persoon in uw organisatie.',
                            'emailAlreadyTakenTitle': 'E-mail al bezet',
                            'emailAlreadyTaken': 'Het e-mailadres dat u hebt opgegeven is al bezet.<br><br>Als u al geregistreerd bent, kunt u inloggen met uw e-mailadres en wachtwoord op <a href="https://learn.micromate.ai">learn.micromate.ai</a>.',
                            'domainValidationFailedTitle': 'E-mail ongeldig',
                            'domainValidationFailed': 'Alleen een e-mail van de organisatie {{organizationName}} is toegestaan voor registratie.'
                        },
                        'selfregistrationwithoutorganisation': {
                            'title': 'Micromaat registratie',
                            'emailAlreadyTakenTitle': 'E-mail al bezet',
                            'emailAlreadyTaken': 'Het e-mailadres dat u hebt opgegeven is al bezet.<br><br>Als u al geregistreerd bent, kunt u inloggen met uw e-mailadres en wachtwoord op <a href="https://learn.micromate.ai">learn.micromate.ai</a>.'
                        }
                    },
                    'organization': 'Organisatie',
                    'email': 'E-mail',
                    'emailRequiredError': 'E-mail moet worden opgegeven',
                    'emailInvalidError': 'E-mail ongeldig',
                    'firstName': 'Voornaam',
                    'firstNameRequiredError': 'Voornaam moet worden opgegeven',
                    'lastName': 'Achternaam',
                    'lastNameRequiredError': 'Achternaam moet worden opgegeven',
                    'language': 'Taal',
                    'password': 'Wachtwoord',
                    'passwordInvalidError': 'Ongeldig wachtwoord formaat',
                    'passwordComplexityPolicyMinLength': 'Moet een minimale lengte hebben van {{minLength}}',
                    'passwordComplexityPolicyHasNumber': 'Moet een getal bevatten',
                    'passwordComplexityPolicyHasLowercase': 'Moet een kleine letter bevatten',
                    'passwordComplexityPolicyHasSymbol': 'Moet een symbool bevatten (bijv. $%*!?)',
                    'passwordComplexityPolicyHasUppercase': 'Moet een hoofdletter bevatten',
                    'confirmPassword': 'Wachtwoord (herhaal',
                    'confirmPasswordInvalidError': 'Wachtwoord komt niet overeen',
                    'agbLinkTitle': 'Algemene voorwaarden',
                    'dataPrivacyLinkTitle': 'Privacybeleid',
                    'dataAccept': 'accepteren',
                    'registrationButton': 'Registreren',
                    'registrationFailedErrorTitle': 'Registratie mislukt',
                    'registrationFailedError': 'Registratie kon niet worden voltooid. Neem contact op met',
                    'invalidCodeError': 'Deze uitnodiging is niet langer geldig. Als u een nieuwe uitnodiging nodig hebt, neem dan contact op met uw Micromate-vertegenwoordiger.',
                    'exchangeCodeError': 'Registratie is momenteel niet mogelijk. Neem contact op met',
                    'parameterErrorTitle': 'Foutieve parameters',
                    'parameterError': 'De opgegeven parameters zijn ongeldig. Neem contact op met de verantwoordelijke persoon in uw organisatie.',
                    'confirmation': {
                        'title': 'Verificatiemail verzonden',
                        'description': 'We hebben je een e-mail gestuurd naar <strong>{email}}</strong> om je e-mailadres te bevestigen. Klik op de link in de e-mail om je registratie te voltooien. Controleer je spam-map als je geen e-mail hebt ontvangen.'
                    }
                },
                'feedback': {
                    'buttonTitle': 'Feedback geven',
                    'title': 'Schrijf je feedback',
                    'sendButton': 'Stuur',
                    'infoBox': 'Uw naam en e-mail worden doorgegeven voor correspondentie',
                    'resultTitle': 'Bedankt voor uw feedback',
                    'resultSuccessMessage': 'Je feedback is naar de verantwoordelijke persoon gestuurd.',
                    'resultErrorMessage': 'De feedback kon niet worden ingediend.',
                    'resultNotAllowFeedbackErrorMessage': 'De feedbackfunctie is niet langer beschikbaar voor deze vraag of dit lespakket.',
                    'resultCloseButton': 'Sluit',
                    'mainTopicTitle': 'Waarover wil je feedback geven?',
                    'topicQuestionButton': 'Huidige vraag',
                    'topicLearningPackageButton': 'Huidig leerpakket',
                    'topicTitle': 'Wat wil je rapporteren?',
                    'questionFaultyTopic': 'De taak is onjuist',
                    'questionUnintelligibleTopic': 'De taak is onbegrijpelijk',
                    'questionSpellingTopic': 'Deze opdracht bevat spelfouten',
                    'questionIdeaTopic': 'Ik heb een idee of opmerking',
                    'learningPackageQuestionSuggestionTopic': 'Konkrete Frage vorschlagen',
                    'learningPackageTopicSuggestionTopic': 'Een specifieke vraag stellen',
                    'learningPackageFeedbackTopic': 'Feedback geven op het lespakket',
                    'questionPreviewHide': 'Vraag verbergen',
                    'questionPreviewShow': 'Toon vraag'
                },
                'popup': {
                    'wizardBackButton': 'Terug'
                },
                'questionPreview': {
                    'questionTitle': 'Vraag',
                    'answerTitle': 'Antwoorden',
                    'answerFalse': 'Niet waar',
                    'answerTrue': 'Waar',
                    'explanationTitle': 'Uitleg',
                    'sourceTitle': 'Bron'
                }
            }
        }];

    constructor(private translateService: TranslateService) {
    }

    public loadTranslations(): void {
        for (const language of this.translations) {
            this.translateService.setTranslation(language.language, language.translations, true);
        }
    }
}
