import { handleDialogMessage as r, dialogTelemetryVersionNumber as o } from "../../internal/dialogHelpers.js";
import { registerHandler as t } from "../../internal/handlers.js";
import { ensureInitialized as e } from "../../internal/internalAPIs.js";
import { getApiVersionTag as i } from "../../internal/telemetry.js";
import { runtime as a } from "../runtime.js";
import * as s from "./adaptiveCard/adaptiveCard.js";
export { s as adaptiveCard };
import * as m from "./update.js";
export { m as update };
import * as n from "./url/url.js";
export { n as url };
function p() {
  t(i(o, "dialog.registerMessageForChildHandler"), "messageForChild", r, !1);
}
function l() {
  return !(!e(a) || !a.supports.dialog);
}
export { p as initialize, l as isSupported };