import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {LocaleViewModel} from './locale-view.model';
import {LocaleService} from '../../services/locale/locale.service';
import {LocaleResponseDto} from '../../services/locale/localeResponseDto';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-locale-select',
    templateUrl: './locale-select.component.html',
    styleUrls: ['./locale-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LocaleSelectComponent),
            multi: true
        }
    ]
})
export class LocaleSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {

    public availableLocals: LocaleViewModel[];
    public onTouched: () => void;
    public onChange: (value: string) => void;
    public disabled: boolean = false;
    public value: string = '';
    private optionsLoaded: boolean = false;
    private pendingValue: string;

    @Input()
    public style: 'dark' | 'light' = 'light';

    constructor(private localeService: LocaleService,
                private translateService: TranslateService) {
    }

    private languageChangedSub: Subscription = new Subscription();

    public ngOnDestroy(): void {
        this.languageChangedSub.unsubscribe();
    }

    public ngOnInit(): void {
        // TODO MIC-2094
        void this.asyncNgOnInit();
    }

    private async asyncNgOnInit(): Promise<void> {
        this.languageChangedSub = this.translateService.onLangChange.subscribe(async () => {
            await this.updateOptions();
            this.selectOptionForValue();
        });
        await this.updateOptions();

        this.optionsLoaded = true;
        this.selectOptionForValue();
    }

    private selectOptionForValue(): void {
        // Timeout is needed, otherwise the inputs are not yet
        // properly there, and the value is not set
        setTimeout(() => {
            this.writeValue(this.pendingValue);
        });
    }

    private async updateOptions(): Promise<void> {
        const [all, supported] = await Promise.all([
            this.localeService.getAll(),
            this.localeService.getSupported()
        ]);

        this.availableLocals = all.map(locale => {
            const name = this.translateService.instant(`shared.locals.${locale.locale.toLocaleLowerCase()}`) as string;
            return new LocaleViewModel(name, locale.locale, this.isSupportedLocale(locale, supported));
        }).sort((a, b) => {
            if (a.isSupported && !b.isSupported) {
                return -1;
            }

            if (!a.isSupported && b.isSupported) {
                return 1;
            }

            return a.name.localeCompare(b.name);
        });
    }

    public writeValue(value: string): void {
        // We cannot update the value, when the options are not yet there
        if (this.optionsLoaded) {
            this.value = value;
            this.pendingValue = undefined;
        } else {
            this.pendingValue = value;
        }
    }

    public registerOnChange(fn: (param: string) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public isSupportedLocale(locale: LocaleResponseDto, supported: LocaleResponseDto[]): boolean {
        return supported.some(x => x.language === locale.language);
    }

    public valueChanged($event: Event): void {
        const control = $event.target as HTMLSelectElement;
        this.value = control.value;
        this.onChange(this.value);
    }
}


