"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EventInterpretation = void 0;
var EventInterpretation = /** @class */function () {
  function EventInterpretation(key, language, eventPayload, inputSource) {
    this.key = key;
    this.language = language;
    this.eventPayload = eventPayload;
    this.inputSource = inputSource;
  }
  EventInterpretation.prototype.getEventPayload = function () {
    return this.eventPayload;
  };
  EventInterpretation.prototype.getAllEntities = function () {
    return this.eventPayload;
  };
  EventInterpretation.prototype.getEntities = function (entityName) {
    var entities = this.eventPayload[entityName];
    if (Array.isArray(entities) || entities === undefined) {
      return entities;
    }
    throw new Error("Entity (".concat(entityName, ") should be an array. Use getEntity()."));
  };
  EventInterpretation.prototype.getEntity = function (entityName) {
    var entity = this.eventPayload[entityName];
    if (typeof entity === 'string' || entity === undefined) {
      return entity;
    }
    throw new Error("Entity (".concat(entityName, ") should be a string but was a string[]. Use getEntities()."));
  };
  EventInterpretation.prototype.getErrorMessage = function () {
    return undefined;
  };
  EventInterpretation.prototype.getIntentName = function () {
    return this.key;
  };
  EventInterpretation.prototype.getIntentGroup = function () {
    return undefined;
  };
  EventInterpretation.prototype.getLanguage = function () {
    return this.language;
  };
  EventInterpretation.prototype.getLocationEntity = function (entityName) {
    return this.getEntity(entityName);
  };
  EventInterpretation.prototype.getMatchingScore = function () {
    return 1;
  };
  EventInterpretation.prototype.getPeriodEntity = function (entityName) {
    return undefined;
  };
  EventInterpretation.prototype.getProcessedText = function () {
    return undefined;
  };
  // Each NLP system returns a different representation of the interpretation
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  EventInterpretation.prototype.getRawInterpretation = function () {
    return undefined;
  };
  EventInterpretation.prototype.getResponse = function () {
    return undefined;
  };
  EventInterpretation.prototype.hasEntity = function (entityName) {
    return this.eventPayload.hasOwnProperty(entityName);
  };
  EventInterpretation.prototype.isFallback = function () {
    return false;
  };
  EventInterpretation.prototype.wasSuccessful = function () {
    return true;
  };
  EventInterpretation.prototype.getInputSource = function () {
    return this.inputSource;
  };
  return EventInterpretation;
}();
exports.EventInterpretation = EventInterpretation;
