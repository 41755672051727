import { registerBeforeSuspendOrTerminateHandler as e, registerOnResumeHandler as r } from "../../internal/handlers.js";
import { ensureInitialized as n } from "../../internal/internalAPIs.js";
import { runtime as t } from "../runtime.js";
function i(r) {
  if (!r) throw new Error("[app.lifecycle.registerBeforeSuspendOrTerminateHandler] Handler cannot be null");
  n(t), e(r);
}
function a(e) {
  if (!e) throw new Error("[app.lifecycle.registerOnResumeHandler] Handler cannot be null");
  n(t), r(e);
}
export { i as registerBeforeSuspendOrTerminateHandler, a as registerOnResumeHandler };