import { ensureInitialized as t } from "./internalAPIs.js";
import { FrameContexts as e, errorNotSupportedOnPlatform as r, DialogDimension as o } from "../public/constants.js";
import { runtime as s } from "../public/runtime.js";
import { sendMessageToParent as i } from "./communication.js";
import { GlobalVars as a } from "./globalVars.js";
import { registerHandler as n, removeHandler as l } from "./handlers.js";
import { getApiVersionTag as m } from "./telemetry.js";
import { isSupported as u } from "../public/dialog/update.js";
import { isSupported as c, getDialogInfoFromUrlDialogInfo as p, getDialogInfoFromBotUrlDialogInfo as f } from "../public/dialog/url/url.js";
import { isSupported as g } from "../public/dialog/url/bot.js";
const d = "v2";
function h(o, a) {
  if (t(s, e.content, e.sidePanel, e.task, e.meetingStage), !u()) throw r;
  i(o, "tasks.updateTask", [a]);
}
function k(o, a, u, f) {
  if (t(s, e.content, e.sidePanel, e.meetingStage), !c()) throw r;
  f && n(m(d, "dialog.url.registerMessageForParentHandler"), "messageForParent", f);
  const g = p(a);
  i(o, "tasks.startTask", [g], (t, e) => {
    null == u || u({
      err: t,
      result: e
    }), l("messageForParent");
  });
}
function j(o, a, u, c) {
  if (t(s, e.content, e.sidePanel, e.meetingStage), !g()) throw r;
  c && n(m(d, "dialog.url.bot.registerMessageForParentHandler"), "messageForParent", c);
  const p = f(a);
  i(o, "tasks.startTask", [p], (t, e) => {
    null == u || u({
      err: t,
      result: e
    }), l("messageForParent");
  });
}
function P(o, a, n) {
  if (t(s, e.task), !c()) throw r;
  i(o, "tasks.completeTask", [a, n ? Array.isArray(n) ? n : [n] : []]);
}
function b(t) {
  return {
    card: t.card,
    height: t.size ? t.size.height : o.Small,
    width: t.size ? t.size.width : o.Small,
    title: t.title
  };
}
function F(t) {
  const e = b(t);
  return e.completionBotId = t.completionBotId, e;
}
const S = [];
function w(t) {
  a.frameContext && (a.frameContext === e.task ? S.push(t) : l("messageForChild"));
}
export { j as botUrlOpenHelper, d as dialogTelemetryVersionNumber, b as getDialogInfoFromAdaptiveCardDialogInfo, F as getDialogInfoFromBotAdaptiveCardDialogInfo, w as handleDialogMessage, S as storedMessages, h as updateResizeHelper, k as urlOpenHelper, P as urlSubmitHelper };