import { sendMessageToParent as e } from "../internal/communication.js";
import { registerHandler as n } from "../internal/handlers.js";
import { ensureInitialized as t } from "../internal/internalAPIs.js";
import { getApiVersionTag as r } from "../internal/telemetry.js";
import { runtime as s } from "./runtime.js";
import { errorNotSupportedOnPlatform as o } from "./constants.js";
const i = "v2";
var u, m;
!function (e) {
  e[e.ifRoom = 0] = "ifRoom", e[e.overflowOnly = 1] = "overflowOnly";
}(u || (u = {}));
class a {
  constructor() {
    this.enabled = !0, this.selected = !1;
  }
}
let f, l, c;
function p() {
  n(r(i, "menus.registerNavBarMenuItemPressHandler"), "navBarMenuItemPress", h, !1), n(r(i, "menus.registerActionMenuItemPressHandler"), "actionMenuItemPress", I, !1), n(r(i, "menus.registerSetModuleViewHandler"), "setModuleView", w, !1);
}
function d(n, u) {
  if (t(s), !P()) throw o;
  c = u, e(r(i, "menus.setUpViews"), "setUpViews", [n]);
}
function w(n) {
  c && c(n) || (t(s), e(r(i, "menus.handleViewConfigItemPress"), "viewConfigItemPress", [n]));
}
function M(n, u) {
  if (t(s), !P()) throw o;
  f = u, e(r(i, "menus.setNavBarMenu"), "setNavBarMenu", [n]);
}
function h(n) {
  f && f(n) || (t(s), e(r(i, "menus.handleNavBarMenuItemPress"), "handleNavBarMenuItemPress", [n]));
}
function v(n, u) {
  if (t(s), !P()) throw o;
  l = u, e(r(i, "menus.showActionMenu"), "showActionMenu", [n]);
}
function I(n) {
  l && l(n) || (t(s), e(r(i, "menus.handleActionMenuItemPress"), "handleActionMenuItemPress", [n]));
}
function P() {
  return !(!t(s) || !s.supports.menus);
}
!function (e) {
  e.dropDown = "dropDown", e.popOver = "popOver";
}(m || (m = {}));
export { u as DisplayMode, a as MenuItem, m as MenuListType, p as initialize, P as isSupported, M as setNavBarMenu, d as setUpViews, v as showActionMenu };