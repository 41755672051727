import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RegistrationComponentFormData} from './RegistrationComponentFormData';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {PasswordComplexityPolicyResponseDto} from '../core/rest/api-types/PasswordComplexityPolicyResponseDto';
import {FormCustomValidation} from '../core/form-custom-validation';
import {RegistrationRessource} from '../core/rest/registration-ressource.service';
import {TranslateService} from '@ngx-translate/core';
import {RegistrationService} from './registration.service';
import {RegistrationComponentFormDataValues} from './RegistrationComponentFormDataValues';
import {RegistrationStrategy} from './strategies/RegistrationStrategy';
import {LocaleService} from '../../../../micromate-learn-lib/src/lib/services/locale/locale.service';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy {

    public registrationLogic: RegistrationStrategy;
    public passwordComplexityPolicy: PasswordComplexityPolicyResponseDto;

    public passwordControl = new FormControl<string>('', [Validators.required, FormCustomValidation.passwordValidator(() => this.passwordComplexityPolicy)]);
    public formData = new FormGroup<RegistrationComponentFormData>({
        firstName: new FormControl<string>('', [Validators.required, Validators.maxLength(100)]),
        lastName: new FormControl<string>('', [Validators.required, Validators.maxLength(100)]),
        email: new FormControl<string>('', [Validators.required, Validators.email]),
        organization: new FormControl<string>('', []),
        locale: new FormControl<string>('', [Validators.required]),
        password: this.passwordControl,
        confirmPassword: new FormControl<string>('', [Validators.required, FormCustomValidation.passwordMatch(() => this.passwordControl.value)]),
        agb: new FormControl<boolean>(false, [Validators.requiredTrue]),
        dataPrivacy: new FormControl<boolean>(false, [Validators.requiredTrue])
    });

    public hasError: boolean = false;
    public errorKey: string;
    public errorTitleKey: string;

    public hasFormError: boolean = false;
    public formErrorKey: string;
    public formErrorTitleKey: string;

    public registrationFailed: boolean = false;

    public isInitializing: boolean = true;
    public registrationRunning: boolean = false;
    public isRegistrationDone: boolean = false;

    private paramsSubscription = new Subscription();

    constructor(private localService: LocaleService,
                private registrationService: RegistrationService,
                private registrationRessource: RegistrationRessource,
                private activatedRoute: ActivatedRoute,
                private translate: TranslateService) {
    }

    public ngOnDestroy(): void {
        if (this.paramsSubscription !== undefined) {
            this.paramsSubscription.unsubscribe();
        }
    }

    public  ngOnInit(): void {
        // TODO MIC-2094
        void this.asyncNgOnInit();
    }

    private async asyncNgOnInit(): Promise<void>  {
        this.passwordComplexityPolicy = await this.registrationRessource.getPasswordComplexityPolicy();
        const bestMatchingLocale = await this.localService.getBestMatching(navigator.language);
        this.translate.use(bestMatchingLocale.supported);
        await this.localService.getSupported();
        this.formData.controls.locale.setValue(bestMatchingLocale.learner);

        this.paramsSubscription = this.activatedRoute.queryParamMap.subscribe(async params => {
            const userId = params.get('userId') ?? undefined;
            const orgCode = params.get('orgCode') ?? undefined;


            this.registrationLogic = this.registrationService.resolveRegistrationTypeFromParameters(userId, orgCode);
            if (this.registrationLogic === undefined) {
                this.isInitializing = false;
                this.hasError = true;
                this.errorKey = 'registration.parameterError';
                this.errorTitleKey = 'registration.parameterErrorTitle';
                return;
            }

            const result = await this.registrationLogic.initialize(this.formData, userId, orgCode);

            if (!result.isValid) {
                this.hasError = true;
                this.errorKey = result.errorKey;
                this.errorTitleKey = result.errorTitleKey;
            }

            this.isInitializing = false;
        });
    }

    public registerLearner: () => Promise<void> = async () => {
        this.hasFormError = false;
        this.registrationRunning = true;
        this.formData.disable();

        try {
            const formData = this.formData.getRawValue() as RegistrationComponentFormDataValues;
            const result = await this.registrationLogic.execute(formData);

            if (!result.isValid) {
                if (result.isGlobalError) {
                    this.hasError = true;
                    this.errorKey = result.errorKey;
                    this.errorTitleKey = result.errorTitleKey;
                } else {
                    this.hasFormError = true;
                    this.formErrorKey = result.errorKey;
                    this.formErrorTitleKey = result.errorTitleKey;
                }

            } else {
                this.isRegistrationDone = true;
            }

        } catch (e) {
            this.registrationFailed = true;
        } finally {
            this.registrationRunning = false;
            this.enableStandardFormFields();
            this.registrationLogic.enableForm(this.formData);
        }
    };

    private enableStandardFormFields(): void {
        this.formData.controls.locale.enable();
        this.formData.controls.agb.enable();
        this.formData.controls.dataPrivacy.enable();
        this.formData.controls.firstName.enable();
        this.formData.controls.lastName.enable();
        this.formData.controls.confirmPassword.enable();
        this.formData.controls.password.enable();
    }


}
