import { __awaiter as i } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.4_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { sendMessageToParent as e, sendAndHandleStatusAndReason as t, callFunctionInHostAndHandleResponse as n, initializeCommunication as o } from "./communication.js";
import { errorLibraryNotInitialized as r, defaultSDKVersionForCompatCheck as s } from "./constants.js";
import { GlobalVars as l } from "./globalVars.js";
import { registerOnThemeChangeHandler as a, initializeHandlers as p } from "./handlers.js";
import { ensureInitializeCalled as u, ensureInitialized as m, processAdditionalValidOrigins as c } from "./internalAPIs.js";
import { getLogger as f } from "./telemetry.js";
import { isNullOrUndefined as d } from "./typeCheckUtilities.js";
import { inServerSideRenderingEnvironment as g, runWithTimeout as h, compareSDKVersions as j } from "./utils.js";
import { Messages as S } from "../public/app/app.js";
import { initialize as v } from "../public/authentication.js";
import { FrameContexts as w } from "../public/constants.js";
import { initialize as y } from "../public/dialog/dialog.js";
import { initialize as z } from "../public/menus.js";
import { runtime as b, applyRuntimeConfig as P, generateVersionBasedTeamsRuntimeConfig as C, mapTeamsVersionToSupportedCapabilities as x, versionAndPlatformAgnosticTeamsRuntimeConfig as D } from "../public/runtime.js";
import { version as E } from "../public/version.js";
import { SimpleTypeResponseHandler as V } from "./responseHandler.js";
import { initialize as K } from "../public/pages/config.js";
const _ = f("app");
function k(i, e) {
  if (g()) {
    return _.extend("initialize")("window object undefined at initialization"), Promise.resolve();
  }
  return h(() => function (i, e) {
    return new Promise(t => {
      l.initializeCalled || (l.initializeCalled = !0, p(), l.initializePromise = o(e, i).then(({
        context: i,
        clientType: e,
        runtimeConfig: t,
        clientSupportedSDKVersion: n = s
      }) => {
        l.frameContext = i, l.hostClientType = e, l.clientSupportedSDKVersion = n;
        try {
          J("Parsing %s", t);
          const i = JSON.parse(t);
          if (J("Checking if %o is a valid runtime object", null != i ? i : "null"), !i || !i.apiVersion) throw new Error("Received runtime config is invalid");
          t && P(i);
        } catch (i) {
          if (!(i instanceof SyntaxError)) throw i;
          try {
            J("Attempting to parse %s as an SDK version", t), isNaN(j(t, s)) || (l.clientSupportedSDKVersion = t);
            const i = JSON.parse(n);
            if (J("givenRuntimeConfig parsed to %o", null != i ? i : "null"), !i) throw new Error("givenRuntimeConfig string was successfully parsed. However, it parsed to value of null");
            P(i);
          } catch (i) {
            if (!(i instanceof SyntaxError)) throw i;
            P(C(l.clientSupportedSDKVersion, D, x));
          }
        }
        l.initializeCompleted = !0;
      }), v(), z(), K(), y()), Array.isArray(e) && c(e), void 0 !== l.initializePromise ? t(l.initializePromise) : J("GlobalVars.initializePromise is unexpectedly undefined");
    });
  }(i, e), 6e4, new Error("SDK initialization timed out."));
}
function A(i) {
  e(i, S.AppLoaded, [E]);
}
function F(i, t) {
  e(i, S.ExpectedFailure, [t.reason, t.message]);
}
function N(i, t) {
  e(i, S.Failure, [t.reason, t.message]);
}
function R(e) {
  return i(this, void 0, void 0, function* () {
    if (l.initializeCompleted) return H(e);
    if (!l.initializePromise) throw new Error(r);
    return l.initializePromise.then(() => H(e));
  });
}
function H(t) {
  return i(this, void 0, void 0, function* () {
    return m(b) && (null === (i = b.supports.app) || void 0 === i ? void 0 : i.notifySuccessResponse) ? n(S.Success, [E], new V(), t).then(() => ({
      hasFinishedSuccessfully: !0
    })) : (e(t, S.Success, [E]), {
      hasFinishedSuccessfully: "unknown"
    });
    var i;
  });
}
const J = _.extend("initializeHelper");
function L(i, e) {
  !d(e) && u(), a(i, e);
}
function O(i, e) {
  return new Promise(n => {
    m(b, w.content, w.sidePanel, w.settings, w.task, w.stage, w.meetingStage), n(t(i, "executeDeepLink", e));
  });
}
export { k as appInitializeHelper, H as callNotifySuccessInHost, A as notifyAppLoadedHelper, F as notifyExpectedFailureHelper, N as notifyFailureHelper, R as notifySuccessHelper, O as openLinkHelper, L as registerOnThemeChangeHandlerHelper };