import n from "./_polyfill-node.global.js";
var r;
function t() {
  if (void 0 === r) {
    var n = new ArrayBuffer(2),
      t = new Uint8Array(n),
      e = new Uint16Array(n);
    if (t[0] = 1, t[1] = 2, 258 === e[0]) r = "BE";else {
      if (513 !== e[0]) throw new Error("unable to figure out endianess");
      r = "LE";
    }
  }
  return r;
}
function e() {
  return void 0 !== n.location ? n.location.hostname : "";
}
function o() {
  return [];
}
function u() {
  return 0;
}
function i() {
  return Number.MAX_VALUE;
}
function a() {
  return Number.MAX_VALUE;
}
function f() {
  return [];
}
function c() {
  return "Browser";
}
function s() {
  return void 0 !== n.navigator ? n.navigator.appVersion : "";
}
function m() {
  return {};
}
function l() {
  return {};
}
function p() {
  return "javascript";
}
function v() {
  return "browser";
}
function d() {
  return "/tmp";
}
var w = d,
  A = "\n";
function E() {
  return "$HOME";
}
var g = {
  homedir: E,
  EOL: "\n",
  arch: p,
  platform: v,
  tmpdir: w,
  tmpDir: d,
  networkInterfaces: m,
  getNetworkInterfaces: l,
  release: s,
  type: c,
  cpus: f,
  totalmem: a,
  freemem: i,
  uptime: u,
  loadavg: o,
  hostname: e,
  endianness: t
};
export { A as EOL, p as arch, f as cpus, g as default, t as endianness, i as freemem, l as getNetworkInterfaces, E as homedir, e as hostname, o as loadavg, m as networkInterfaces, v as platform, s as release, d as tmpDir, w as tmpdir, a as totalmem, c as type, u as uptime };