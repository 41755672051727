import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MovieService} from './movie.service';
import {VideoLink} from './videoLinkFormats/VideoLink';
import {DefaultVideo} from './videoLinkFormats/DefaultVideo';
import {VideoDisplay} from './videoLinkFormats/VideoFormat';
import {TaskModuleMediaData} from '../../types/TaskModuleMediaData';
import {EnvironmentService} from '../../services/EnvironmentService';

@Component({
    selector: 'mm-media',
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {
    @Input()
    public mediaData: TaskModuleMediaData;

    @Input()
    public autoplay: boolean;

    @Input()
    public size: 'fullScreen' | 'insidePopup' | 'medium' = 'medium';

    public invalidMedia = false;
    public videoLink: VideoLink = DefaultVideo.createVideoLink();
    public showIframe: boolean = false;

    @Output()
    public mediaLoaded: EventEmitter<void> = new EventEmitter<void>();

    constructor(private movieService: MovieService, private environmentService: EnvironmentService) {
    }

    public ngOnInit(): void {
        if (this.mediaData !== undefined) {
            switch (this.mediaData.mediaType) {
                case 'audio':
                    this.validateAudioElement(this.mediaData.mediaUrl);
                    break;
                case 'movie':
                    this.validateVideoElement(this.mediaData.mediaUrl);
                    break;
                case 'website':
                    this.validateWebsiteElement();
                    break;
                default:
                    this.mediaLoadingFinished();
                    break;
            }
        } else {
            this.mediaLoadingFinished();
        }
    }

    public getAudioImageUrl(): string {
        return this.mediaData.imageUrl !== undefined ? this.mediaData.imageUrl : `${this.environmentService.environment.manageUrl}/assets/media/audio-info.png`;
    }

    public mediaLoadingFinished(): void {
        this.mediaLoaded.emit();
    }

    private validateVideoElement(mediaUrl: string): void {
        this.videoLink = this.movieService.getVideoType(mediaUrl);
        this.showIframe = this.videoLink.display === VideoDisplay.IFRAME;

        if (this.videoLink.display === VideoDisplay.VIDEO) {
            const videoElement = document.createElement('video');
            videoElement.onerror = (() => {
                if (videoElement.error.code === 4) {
                    this.invalidMedia = true;
                }
                videoElement.remove();
            }) as OnErrorEventHandler;
            videoElement.src = mediaUrl;
        }
    }

    private validateAudioElement(mediaUrl: string): void {
        const audioElement = document.createElement('audio');
        audioElement.onerror = (() => {
            if (audioElement.error.code === 4) {
                this.invalidMedia = true;
            }
            audioElement.remove();
        }) as OnErrorEventHandler;
        audioElement.src = mediaUrl;
    }

    private validateWebsiteElement(): void {
        this.showIframe = true;
    }
}
