import {ChangeDetectorRef, Injectable} from '@angular/core';
import {take} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {DialogViewModelCreatorService} from 'botato-angular-lib';

/* eslint-disable @typescript-eslint/no-explicit-any*/
@Injectable({
    providedIn: 'root'
})
export class DeactivateMessageService {

    constructor(private dialogViewModelCreatorService: DialogViewModelCreatorService) {
    }

    public deactivateOldCustomMessage(data: any): Subscription {
        if (data.deactivated !== true) {
            return this.dialogViewModelCreatorService.getDialogViewModelObservable().pipe(take(1)).subscribe((dialogViewModel) => {
                if (data.numberOfMessages === undefined) {
                    data.numberOfMessages = dialogViewModel.messageGroups.length;
                } else {
                    const isOldCustomMessageShouldBeDeactivated = data.deactivated === undefined &&
                        data.numberOfMessages < dialogViewModel.messageGroups.length;
                    if (isOldCustomMessageShouldBeDeactivated) {
                        data.deactivated = true;
                    }
                }
            });
        }

        return undefined;
    }

    public setUpdateOfLastMessage(data: any, changeDetection: ChangeDetectorRef): Subscription {
        if (data.updated !== true) {
            return this.dialogViewModelCreatorService.getDialogViewModelObservable().pipe(
                take(1))
                .subscribe((dialogViewModel) => {
                    const messageGroupsLength = dialogViewModel.messageGroups.length;
                    const messageViewModelsLength = dialogViewModel.messageGroups[messageGroupsLength - 3].messages;
                    for (const [i, message] of messageViewModelsLength.entries()) {
                        if (i === (messageViewModelsLength.length - 1)) {

                            message.data.updateMessage = data;
                            data.updated = true;
                            changeDetection.detectChanges();
                        }
                    }
                });
        }

        return undefined;
    }
}
