"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SenderIdentity = void 0;
var SenderIdentity = /** @class */function () {
  function SenderIdentity(
  // Unique id used for NLP, session
  sessionId,
  // This will be the adress to send a message
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  // TODO Probably can be typed. Check?
  senderAddress,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  loggableSenderAddress,
  // Unique id of user and conversation (outside of adapter only for logging/reporting, only available on some adapters)
  conversationId,
  // Full user id of application (identity), for single channel bots this can be the same as the channelUserId
  applicationUserId,
  // Full user id of channel (outside of adapter only for logging/reporting, only available on some adapters)
  channelUserId,
  // Unique MessagingAdapterIdentifier
  messagingAdapterIdentifier,
  // Locale language information of identity in channel
  locale,
  // Unique "Mandanten/Client-ID"
  clientId,
  // Unique id used to specify a bot
  botId) {
    this.sessionId = sessionId;
    this.senderAddress = senderAddress;
    this.loggableSenderAddress = loggableSenderAddress;
    this.conversationId = conversationId;
    this.applicationUserId = applicationUserId;
    this.channelUserId = channelUserId;
    this.messagingAdapterIdentifier = messagingAdapterIdentifier;
    this.locale = locale;
    this.clientId = clientId;
    this.botId = botId;
  }
  return SenderIdentity;
}();
exports.SenderIdentity = SenderIdentity;
