import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BotatoChatManagerService, DelayedRenderedMessageComponent} from 'botato-angular-lib';
import {QuizResponseItemData} from '../../type/QuizResponseItemData';
import {ResponseItemDataTextService} from '../../../response-item-data-text.service';
import {MultipleTextChoiceQuizResponseItemDataText} from './MultipleTextChoiceQuizResponseItemDataText';
import {MultipleTextChoiceQuiz} from './MultipleTextChoiceQuiz';
import {DeactivateMessageService} from '../../../deactivate-message.service';
import {Subscription} from 'rxjs';
import {QuizQuestion} from '../../type/QuizQuestion';
import {MultipleTextChoiceQuizUpdateAnswer} from './MultipleTextChoiceQuizUpdateAnswer';

@Component({
    selector: 'mm-multiple-text-choice-quiz',
    templateUrl: './multiple-text-choice-quiz.component.html'
})
export class MultipleTextChoiceQuizComponent extends DelayedRenderedMessageComponent implements OnInit, OnDestroy {

    public static TYPE = 'MultipleTextChoice';
    public static UPDATE_TYPE = 'updateMultipleTextChoiceQuiz';

    public responseItemData: QuizResponseItemData<MultipleTextChoiceQuiz, MultipleTextChoiceQuizResponseItemDataText, MultipleTextChoiceQuizUpdateAnswer>;
    public question: QuizQuestion;
    public answers: { id: number; letter: string; text: string; checked: boolean; isCorrect: boolean; }[];
    public quizNumber: string;
    public isDeactivated: boolean;
    public updatedMessage = false;
    public sourceUrl: string;
    public sourceLabel: string;
    public questionTranslationId: string;
    public learningPackageTranslationId:string;
    private subscription: Subscription;
    public shouldShowMessage: boolean = false;

    constructor(private botatoChatManagerService: BotatoChatManagerService,
                private responseItemDataTextService: ResponseItemDataTextService,
                private deactivateMessageService: DeactivateMessageService,
                private changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    public ngOnDestroy(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }

    public ngOnInit(): void {
        if (this.message.type === MultipleTextChoiceQuizComponent.TYPE) {
            this.shouldShowMessage = true;
            this.updatedMessage = this.isMessageUpdated();
            if (this.updatedMessage) {
                this.createMessageUpdateData();
            } else {
                this.subscription = this.deactivateMessageService.deactivateOldCustomMessage(this.message.data);

                this.isDeactivated = this.message.data.deactivated !== undefined ? this.message.data.deactivated : false;

                this.createMessageData();
            }
        }

        if (this.message.type === MultipleTextChoiceQuizComponent.UPDATE_TYPE) {
            this.subscription = this.deactivateMessageService.setUpdateOfLastMessage(this.message.data, this.changeDetectorRef);
        }
    }

    public getLetterFromAlphabet(index: number): string {
        return String.fromCharCode(65 + index);
    }

    public getAnswerText(answer: { letter: string; text: string; checked: boolean; }): string {
        return `${answer.letter}) ${answer.text}`;
    }

    public sendAnswers(): void {
        if (this.isAnyAnswerMarked()) {
            const answers = this.answers.filter(answer => answer.checked).map(answer => answer.letter).join(', ');
            this.botatoChatManagerService.sendUserMessage(answers, 'quickreply');
        }

    }

    public isAnyAnswerMarked(): boolean {
        return this.answers.filter(answer => answer.checked).length > 0;
    }

    public isMessageUpdated(): boolean {

        return this.message.data.updateMessage?.responseItemData !== undefined;
    }

    public mediaLoaded(): void {
        this.componentRenderingCompleted();
    }

    private createMessageData(): void {

        this.responseItemData = this.message.data.responseItemData;
        this.question = this.responseItemData.quiz.quiz;
        this.quizNumber = this.responseItemData.learningSessionData.quizNumber;
        this.answers = this.responseItemData.quiz.answers.map((answer, index: number) => {
            return {
                id: answer.id,
                letter: this.getLetterFromAlphabet(index),
                text: this.responseItemDataTextService.replaceText(answer.answerText),
                checked: false,
                isCorrect: answer.isCorrectAnswer
            };
        });
    }

    private createMessageUpdateData(): void {

        this.responseItemData = this.message.data.updateMessage.responseItemData;
        this.question = this.responseItemData.quizAnswerData.quiz;
        this.quizNumber = this.responseItemData.quizAnswerData.quizNumber;
        this.sourceUrl = this.responseItemData.quizAnswerData.sourceUrl;
        this.sourceLabel = this.responseItemData.quizAnswerData.sourceLabel;
        this.questionTranslationId = this.responseItemData.quizAnswerData.id;
        this.learningPackageTranslationId = this.responseItemData.learningPackageTranslationId;
        this.answers = this.responseItemData.quizAnswerData.answers.map((answer, index: number) => {
            return {
                id: answer.id,
                letter: this.getLetterFromAlphabet(index),
                text: this.responseItemDataTextService.replaceText(answer.answerText),
                checked: false,
                isCorrect: answer.isCorrectAnswer
            };
        });
    }

    public override onAnyMessageSent(): void {
        if (!this.isDeactivated) {

            this.message.data.deactivated = true;
        }
    }
}
